import { Flex, Text } from '@mantine/core';
import { LogoutButton } from '../../navigation/LogoutButton';
import { useTranslation } from 'react-i18next';
import { ErrorCode } from '@web/common';

export const NoData = ({ errorCode }: { errorCode: ErrorCode }) => {
  const { t } = useTranslation('participant-dashboard');

  return (
    <Flex h="100vh" justify="center">
      <Flex
        direction="column"
        p="20px"
        m="auto"
        bg="#fff"
        gap="20px"
        style={{ borderRadius: 5 }}
      >
        <Text size="lg" fw="500" ta="center" p="15px">
          {t('Errors.NoData')}
        </Text>
        <Text maw="400px" p="0px 15px">
          {t('Errors.NoDataMessage', { errorCode: errorCode })}
        </Text>
        <LogoutButton smallIcon={false} />
      </Flex>
    </Flex>
  );
};

import {
  getQrCodeContentResDto,
  participantResponsibleUserResDto,
  SetQrCodeContentResDto,
} from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';
import { z } from 'zod';

export const getParticipantQrCodeContent = async () => {
  return authenticatedAPI.get(
    `/api/participant/qr-code-content`,
    getQrCodeContentResDto,
  );
};

export const setParticipantQrCodeContent = async (
  participantId: string,
  body: SetQrCodeContentResDto,
) => {
  return authenticatedAPI.post(
    `/api/participant/${participantId}/qr-code-content`,
    body,
    z.void(),
  );
};

export const participantResponsibleUser = async (participantId: string) => {
  return authenticatedAPI.get(
    `/api/participant/${participantId}/responsible-user`,
    participantResponsibleUserResDto,
  );
};

import { Outlet } from 'react-router-dom';
import { Loading } from '../../component';
import { useKeycloakStore } from '../../store';
import { observer } from 'mobx-react';

export const AuthenticatedRoot = observer(() => {
  const kcStore = useKeycloakStore();

  if (!kcStore.initialized) {
    kcStore.initKeycloak();
    return <Loading />;
  }

  if (!kcStore.authenticated) {
    void kcStore.login();
    return;
  }

  return <Outlet />;
});

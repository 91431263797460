import { observer } from 'mobx-react';
import { useKeycloakStore } from './store';
import { RouterProvider } from 'react-router-dom';
import { createRoutes } from './route/createRoutes';
import { MantineProvider } from '@mantine/core';
import { mantineCustomTheme } from './theme/mantineCustomTheme';
import { Notifications } from '@mantine/notifications';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSnapPixelStore } from './store/SnapPixelStore';
import { useGoogleAnalyticsStore } from './store/GoogleAnalyticsStore';
import { Loading } from './component';
import { ShopProvider } from './ShopProvider';
import { DatesProvider } from '@mantine/dates';
import { useMyCohortStore } from './store/MyCohortStore';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

export const App = observer(() => {
  const kcStore = useKeycloakStore();
  const myCohortStore = useMyCohortStore();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();

  useEffect(() => {
    snapPixelStore.init();
  }, [snapPixelStore]);

  useEffect(() => {
    googleAnalyticsStore.init();
  }, [googleAnalyticsStore]);

  const cohortDetailsForRoutes = !myCohortStore.cohort?.cohort
    ? null
    : {
        startDate: myCohortStore.cohort.cohort.startDate,
        cohortType: myCohortStore.cohort.cohort.cohortType,
      };

  return (
    <>
      <Helmet>
        <title>Cannabis Studie Zürich: Swiss Cannabis Research</title>
        <meta name="version" content={process.env['NX_VERSION']} />
        <meta
          name="description"
          content="Sie möchten an der Cannabis Studie in Zürich zur Evaluation des Freizeit-Cannabiskonsums in der Schweiz teilnehmen? Hier geht’s zur Anmeldung ►►►"
        />
      </Helmet>
      <MantineProvider withCssVariables theme={mantineCustomTheme}>
        <DatesProvider settings={{ locale: 'de' }}>
          <ShopProvider>
            <Suspense fallback={<Loading />}>
              <Notifications position="top-center" limit={2} />
              <RouterProvider
                router={createRoutes(
                  kcStore.currentRealmRole,
                  cohortDetailsForRoutes,
                )}
              />
            </Suspense>
          </ShopProvider>
        </DatesProvider>
      </MantineProvider>
    </>
  );
});

import {
  emailVerificationSitePath,
  entryQuestionnairePath,
  fastTrackPath,
  quickEnroll,
  rescheduleAppointmentPath,
  selfDeactivationVerificationSitePath,
} from '@web/common';

export enum Routes {
  Root = '/',
  Faq = '/faq',
  EntryQuestionnaire = `/${entryQuestionnairePath}`,
  Register = '/register',
  NotEligible = '/not-eligible',
  Enroll = `/enroll`,
  QuickEnroll = `/${quickEnroll}`,
  Study = '/study',
  StammdatenQuestionnaire = 'stammdaten-questionnaire',
  CannabisConsumptionQuestionnaire = 'cannabis-consumption-questionnaire',
  SF12Questionnaire = 'sf12-questionnaire',
  BookInPerson = 'book-in-person',
  RescheduleAppointment = rescheduleAppointmentPath,
  Deactivated = 'deactivated',
  SelfDeactivation = 'self-deactivation',
  Nutzungsbedingungen = '/nutzungsbedingungen',
  Impressum = '/impressum',
  Datenschutz = '/datenschutz',
  TestPage = '/test-page',
  VerifyEmail = `${emailVerificationSitePath}`,
  VerifyDeactivation = `${selfDeactivationVerificationSitePath}`,

  // participant
  Profile = 'profile',
  SimpleOnboardingChecklist = fastTrackPath,
  Shop = 'shop',
  Konsum = 'konsum',
  ShopOrder = 'shop/order',
  OpenShopOrders = 'open-orders',
  Points = 'points',
  Podcasts = 'podcasts',

  // study owner
  PausedUsers = 'users-on-hold',
  Statistics = 'statistics',
  FilterUsers = 'filter-users',
  User = 'user',
  UserDetailEntryQuestionnaire = 'view-entry-questionnaire',
  UserDetailStammdatenQuestionnaire = 'view-stammdaten-questionnaire',
  UserDetailCannabisConsumptionQuestionnaire = 'view-cannabis-consumption-questionnaire',
  UserDetailSF12Questionnaire = 'view-sf12-questionnaire',
}

import { Flex, Text } from '@mantine/core';
import { BaseLayout } from '../../navigation';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { useShopStore, useWaitingStateStore } from '../../../../store';
import { useAvailableThcAmount, useWindowSize } from '../../../../hooks';
import { Loading } from '../../../../component';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ProductList } from './components/ProductList';
import { FeatureAccess } from '../../../../util/FeatureAccess';
import { FeatureFlag } from '@web/common';
import { OpenOrders } from './components/OpenOrders';

export const ShopScreen = observer(() => {
  const shopStore = useShopStore();
  const products = shopStore.products;
  const isSmallScreen = useWindowSize(500);
  const { t } = useTranslation('shop');
  const waitingStateStore = useWaitingStateStore();
  const { currentThcUnit, availableAmount } = useAvailableThcAmount();
  const isLoading = waitingStateStore.loadingAccessState;

  useEffect(() => {
    shopStore.loadProducts();
  }, [shopStore]);

  if (isLoading) return <Loading />;

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={20}
        bg="#fff"
        align="flex-start"
        p={isSmallScreen ? 20 : 40}
        className={classes.borderRadius}
      >
        <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
          <OpenOrders />
        </FeatureAccess>
        <Text fw="500" size="28px" lh="38px">
          {t('Title')}
        </Text>
        <Text size="14px" lh="21px">
          {t('AvailableAmountMessage', { availableAmount, currentThcUnit })}
        </Text>

        <ProductList products={products} />
      </Flex>
    </BaseLayout>
  );
});

import { Box, Button, Text } from '@mantine/core';
import classes from './MobileNavBar.module.css';
import { MyIcon } from '../../../../component';

export const MobileNavItem = ({
  iconName,
  text,
  route,
  onClick,
}: {
  iconName: string;
  text: string | JSX.Element;
  route: string;
  onClick?: () => void;
}) => {
  const isCurrentRoute = route === window.location.pathname;
  const foreGroundColor = isCurrentRoute ? '#fff' : '#202020';
  return (
    <Box p="0px 5px">
      <Button
        className={classes.button}
        variant="white"
        justify="flex-start"
        size="lg"
        w={isCurrentRoute ? 'fit-content' : '50px'}
        leftSection={
          <MyIcon
            name={iconName}
            className={classes.icon}
            style={{ fill: foreGroundColor }}
          />
        }
        onClick={onClick}
        bg={isCurrentRoute ? '#202020' : '#F7F6F1'}
      >
        <Text className={classes.navBtnText} style={{ color: foreGroundColor }}>
          {text}
        </Text>
      </Button>
    </Box>
  );
};

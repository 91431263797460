import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useUserMeStore, useWaitingStateStore } from '../../../store';
import { BookedChecklist } from './booked-appointment/BookedChecklist';
import { useWindowSize } from '../../../hooks';
import { WaitingState } from './waiting-phase/WaitingState';
import { UserStatus } from '@prisma/client';
import useRedirect from '../../../hooks/useRedirect';
import { OnboardingChecklistWrapper } from './onboarding-checklist/OnboardingChecklistWrapper';
import { Loading } from '../../../component';
import { HomeScreenWrapper } from './home-screen/HomeScreenWrapper';
import { NoData } from './deactivated';
import { ErrorCode } from '@web/common';

export const Dashboard = observer(() => {
  const myInfoStore = useUserMeStore();
  const isSmallScreen = useWindowSize(600);
  const waitingStateStore = useWaitingStateStore();
  useRedirect();

  useEffect(() => {
    myInfoStore.loadMyData();
    myInfoStore.loadMyLatestAppointment();
  }, [myInfoStore]);

  if (waitingStateStore.canAccessStudy) return <HomeScreenWrapper />;
  if (myInfoStore.myInfo?.status === UserStatus.ACTIVE) return <WaitingState />;

  // when rendering components conditionally, don't put hooks inside them, but pass them
  const userInformation = myInfoStore.appointment ? (
    <BookedChecklist isSmallScreen={isSmallScreen} />
  ) : (
    <OnboardingChecklistWrapper />
  );

  if (myInfoStore.myInfo) return userInformation;
  else if (myInfoStore.loadingInfo) return <Loading />;

  return <NoData errorCode={ErrorCode.CannotLoadDashboard} />;
});

import { Flex, Button, Text, ActionIcon } from '@mantine/core';
import { ExportModal, MyIcon } from '../../../component';
import { FeatureFlag, RealmRole } from '@web/common';
import { useKeycloakStore } from '../../../store';
import classes from './Layout.module.css';
import { useExportStore } from '../../../store/ExportStore';
import { observer } from 'mobx-react';
import { LogoutButton } from './LogoutButton';
import { useTranslation } from 'react-i18next';
import { ShoppingCartButton } from './ShoppingCartButton';
import { FeatureAccess } from '../../../util/FeatureAccess';
import useNavItems from './useNavItems';

export const NavBar = observer(
  ({
    navBarOpen,
    setNavBarOpen,
  }: {
    navBarOpen: boolean;
    setNavBarOpen: (open: boolean) => void;
  }) => {
    const kcStore = useKeycloakStore();
    const exportStore = useExportStore();
    const { t } = useTranslation('navbar');
    const currentRole = kcStore.currentRealmRole;
    const navItemElements = useNavItems(false);

    return (
      <Flex className={classes.navBar}>
        <Flex justify={navBarOpen ? 'flex-end' : 'center'} px="5px">
          <ActionIcon
            variant="white"
            onClick={() => setNavBarOpen(!navBarOpen)}
          >
            <MyIcon name={navBarOpen ? 'chevron_collapse' : 'chevron_expand'} />
          </ActionIcon>
        </Flex>
        <Flex
          className={classes.innerNavBar}
          p={navBarOpen ? '15px 15px' : '15px 0px'}
        >
          <Flex w="100%" gap="15px" direction="column">
            {navItemElements}
          </Flex>

          <Flex p="0px 5px" w="100%" gap="10px" direction="column">
            {currentRole === RealmRole.StudyOrganizer && (
              <>
                <Button
                  className={classes.button}
                  variant={navBarOpen ? 'outline' : 'white'}
                  justify="flex-start"
                  w="100%"
                  leftSection={
                    <MyIcon name="export" className={classes.icon} />
                  }
                  size="lg"
                  onClick={() => (exportStore.open = true)}
                >
                  <Text className={classes.navBtnText}>{t('ExportData')}</Text>
                </Button>
                <ExportModal />
                <LogoutButton smallIcon={navBarOpen} />
              </>
            )}

            <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
              {currentRole === RealmRole.Participant && <ShoppingCartButton />}
            </FeatureAccess>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

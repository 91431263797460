import { observer } from 'mobx-react';
import { PodcastListVersion, Podcasts } from './Podcasts';
import { useTranslation } from 'react-i18next';
import { StudyDashboardDetailPage } from '../../../../component/page';

export const PodcastsView = observer(() => {
  const { t } = useTranslation('consumption');

  return (
    <StudyDashboardDetailPage breadcrumbText={`/ ${t('Consumption')}`}>
      <Podcasts version={PodcastListVersion.Long} />
    </StudyDashboardDetailPage>
  );
});

import { z } from 'zod';

export enum StammdatenQuestionnaireQuestionKey {
  Name = 'name',
  Surname = 'surname',
  Address = 'address',
  PLZ = 'plz',
  Ort = 'ort',
  BirthDate = 'birthdate',
  AHVNumber = 'ahv-number',

  // socio-economic
  Sex = 'sex',
  BirthPlaceAbroad = 'birth-place-abroad',
  BirthPlace = 'birth-place',
  BirthPlaceMunicipality = 'birth-place-municipality',
  BirthPlaceCanton = 'birth-place-canton',

  SwissNationality = 'swiss-nationality',
  OtherNationality = 'other-nationality', // if "no" in SwissNationality
  GeburtsOrt = 'geburts-ort', // if "no" in SwissNationality
  PassportType = 'passport-type', // if "no" in SwissNationality
  HeimatOrt = 'heimat-ort', // if "yes" in SwissNationality
  AusweisType = 'ausweis-type', // if "yes" in SwissNationality
  AusweisNr = 'ausweis-nr',
  CurrentLivingSituation = 'current-living-situation',
  CivilStatus = 'civil-status',

  Clustering = 'clustering',
  ClusteringName = 'clustering-name',
  ClusteringSurname = 'clustering-surname',
}

export enum SexEnum {
  Male = 'male',
  Female = 'female',
  Divers = 'divers',
}

export enum OtherNationalityEnum {
  Italy = 'italy',
  Germany = 'germany',
  Portugal = 'portugal',
  France = 'france',
  Kosovo = 'kosovo',
  Spain = 'spain',
  Turkey = 'turkey',
  Serbia = 'serbia',
}

export enum PassportTypeEnum {
  PassportC = 'passport-c',
  PassportB = 'passport-b',
  PassportA = 'passport-a',
  OtherPassport = 'other-passport',
}

export enum AusweisTypEnum {
  Reisepass = 'Reisepass',
  Ausweis = 'ausweis',
}

export enum CurrentLivingSituationEnum {
  OnePersonHousehold = 'one-person-household',
  PairWithoutChildren = 'pair-without-children',
  PairWithChildren = 'pair-with-children',
  OneParentWithChildrenBelow18 = 'one-parent-with-children-below-18',
  OtherHouseholdWithMorePersons = 'other-household-with-more-persons',
}

export enum CivilStatusEnum {
  Single = 'single',
  Married = 'married',
  Widowed = 'widowed',
  Divorced = 'divorced',
  RegisteredPartnership = 'registered-partnership',
  DissolvedPartnership = 'dissolved-partnership',
}

export const stammdatenFieldLength1 = 128;
export const stammdatenFieldLength2 = 255;
export const stammdatenFieldLength3 = 512;
export const stammdatenFieldLength4 = 1024;
export const stammdatenPLSMinMax = [1000, 99999];
export const stammdatenQuestionnaireBirthdateSchema = z
  .string()
  .regex(/^\d{2}-\d{2}-\d{4}$/);

export const stammdatenQuestionnaireReqDto = z.object({
  [StammdatenQuestionnaireQuestionKey.Name]: z
    .string()
    .max(stammdatenFieldLength2)
    .transform((str) => normalizeString(str)),
  [StammdatenQuestionnaireQuestionKey.Surname]: z
    .string()
    .max(stammdatenFieldLength2)
    .transform((str) => normalizeString(str)),
  [StammdatenQuestionnaireQuestionKey.Address]: z
    .string()
    .max(stammdatenFieldLength4),
  [StammdatenQuestionnaireQuestionKey.PLZ]: z.coerce
    .number()
    .min(stammdatenPLSMinMax[0])
    .max(stammdatenPLSMinMax[1]),
  [StammdatenQuestionnaireQuestionKey.Ort]: z
    .string()
    .max(stammdatenFieldLength3),
  [StammdatenQuestionnaireQuestionKey.BirthDate]:
    stammdatenQuestionnaireBirthdateSchema,
  [StammdatenQuestionnaireQuestionKey.AHVNumber]: z.string().regex(/^\d{13}$/),

  // socio-economic
  [StammdatenQuestionnaireQuestionKey.Sex]: z.nativeEnum(SexEnum),
  [StammdatenQuestionnaireQuestionKey.BirthPlaceAbroad]: z.coerce.boolean(),
  [StammdatenQuestionnaireQuestionKey.BirthPlace]: z
    .object({
      [StammdatenQuestionnaireQuestionKey.BirthPlaceMunicipality]: z
        .string()
        .max(stammdatenFieldLength2),
      [StammdatenQuestionnaireQuestionKey.BirthPlaceCanton]: z
        .string()
        .max(stammdatenFieldLength2),
    })
    .optional(),
  [StammdatenQuestionnaireQuestionKey.SwissNationality]: z.boolean(),
  [StammdatenQuestionnaireQuestionKey.OtherNationality]: z
    .union([z.nativeEnum(OtherNationalityEnum), z.string()])
    .optional(),
  [StammdatenQuestionnaireQuestionKey.GeburtsOrt]: z
    .string()
    .max(stammdatenFieldLength3)
    .optional(),
  [StammdatenQuestionnaireQuestionKey.PassportType]: z
    .nativeEnum(PassportTypeEnum)
    .optional(),
  [StammdatenQuestionnaireQuestionKey.HeimatOrt]: z
    .string()
    .max(stammdatenFieldLength1)
    .transform((str) => removeSpecialCharacters(str))
    .optional(),
  [StammdatenQuestionnaireQuestionKey.AusweisType]: z
    .nativeEnum(AusweisTypEnum)
    .optional(),
  [StammdatenQuestionnaireQuestionKey.AusweisNr]: z
    .string()
    .max(stammdatenFieldLength1),
  [StammdatenQuestionnaireQuestionKey.CurrentLivingSituation]: z.nativeEnum(
    CurrentLivingSituationEnum,
  ),
  [StammdatenQuestionnaireQuestionKey.CivilStatus]:
    z.nativeEnum(CivilStatusEnum),
  [StammdatenQuestionnaireQuestionKey.Clustering]: z
    .array(
      z.object({
        [StammdatenQuestionnaireQuestionKey.ClusteringName]: z
          .string()
          .max(stammdatenFieldLength2),
        [StammdatenQuestionnaireQuestionKey.ClusteringSurname]: z
          .string()
          .max(stammdatenFieldLength2),
      }),
    )
    .optional(),
});
export type StammdatenQuestionnaireReqDto = z.infer<
  typeof stammdatenQuestionnaireReqDto
>;

// this is used as an output schema for the UI questionnaire
export const questionnaireOutputSchema = stammdatenQuestionnaireReqDto.extend({
  [StammdatenQuestionnaireQuestionKey.BirthDate]: z.string(),
  [StammdatenQuestionnaireQuestionKey.Clustering]: z
    .array(
      z.object({
        [StammdatenQuestionnaireQuestionKey.ClusteringName]: z
          .string()
          .max(stammdatenFieldLength2)
          .optional(),
        [StammdatenQuestionnaireQuestionKey.ClusteringSurname]: z
          .string()
          .max(stammdatenFieldLength2)
          .optional(),
      }),
    )
    .optional(),
});
export type QuestionnaireOutput = z.infer<typeof questionnaireOutputSchema>;

function removeSpecialCharacters(str: string) {
  return str.replace(/[-,]/g, ' ');
}

function normalizeString(str: string) {
  return str
    .replace(/[ä]/g, 'ae')
    .replace(/[ö]/g, 'oe')
    .replace(/[ü]/g, 'ue')
    .replace(/[Ä]/g, 'AE')
    .replace(/[Ö]/g, 'OE')
    .replace(/[Ü]/g, 'UE')
    .replace(/[ß]/g, 'ss')
    .replace(/-/g, ' ')
    .normalize('NFKD')
    .replace(/[\u0300-\u036F]/g, ''); // replaces accented characters
}

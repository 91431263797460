import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import { showErrorNotification } from '../component/notification';
import { AvailableMission } from '@web/common';
import { getFunds } from '../request/authenticated-requests/contest';

export class ContestStore {
  @observable public contests: AvailableMission[] = [];
  @observable public funds: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action public loadFunds = () => {
    getFunds()
      .then((res) => runInAction(() => (this.funds = res.funds)))
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<ContestStore>();
export const useContestStore = useStore;
export const ContestStoreProvider = provider;

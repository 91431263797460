import { showNotification } from '@mantine/notifications';
import { PointsAwardedNotification } from './PointsAwardedNotification';
import pointsAwardedClasses from './PointsAwardedNotification.module.css';
import { baseNotificationProps } from '../baseNotificationProps';
import { LoyalityPointsLogReason } from '@prisma/client';

export const showPointsAwardedNotification = (
  points: number,
  occasion: LoyalityPointsLogReason,
) => {
  showNotification({
    ...baseNotificationProps,
    message: <PointsAwardedNotification points={points} occasion={occasion} />,
    classNames: pointsAwardedClasses,
    withCloseButton: false,
  });
};

import { DateTime } from 'luxon';
import { lockedHrPlusMonths } from '../constants';
import { CohortType } from '@prisma/client';

// check if cohortStartDate of user is 4+ months ago
/**
 * do not use this function, but rather use the function below
 * @param startDate
 */
export function canAccessHrPlusContent(startDate: DateTime) {
  const monthsDifference = DateTime.now().diff(startDate, 'months').months;
  return monthsDifference >= lockedHrPlusMonths;
}

export function hrPlusContentReady(
  cohortType: CohortType,
  startDate: DateTime,
) {
  return (
    cohortType === CohortType.LIMITED_ACCESS &&
    canAccessHrPlusContent(startDate)
  );
}

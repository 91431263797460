import { Flex, Box, Text } from '@mantine/core';
import { useWindowSize } from '../../hooks';
import { observer } from 'mobx-react';
const smallScreenWidth = 760;

type BannerProps = {
  maxWidth?: string;
  padding?: string;
  margin?: string;
  link?: string | null;
  title: string;
  description: string;
};

export const Banner = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
    margin = 'auto',
    title,
    description,
    link,
  }: BannerProps) => {
    const isSmallScreen = useWindowSize(smallScreenWidth);

    return (
      <Flex
        justify={isSmallScreen ? 'flex-start' : 'center'}
        p={padding}
        gap="30px"
        wrap={isSmallScreen ? 'wrap' : 'nowrap'}
        maw={maxWidth}
        m={margin}
      >
        <Box p="20px" style={{ borderRadius: 8, border: 'solid 2px red' }}>
          <Text fw="600">{title}</Text>
          <Text>{description}</Text>
          {link && (
            <a style={{ wordBreak: 'break-word' }} href={link}>
              {link}
            </a>
          )}
        </Box>
      </Flex>
    );
  },
);

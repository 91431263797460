import { observer } from 'mobx-react';
import { Button, Indicator, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { MyIcon } from '../../../component';
import classes from './Layout.module.css';
import { ShoppingCartDrawer } from '../participant/shop';
import { useShopStore } from '../../../store';
import { useCart } from '@shopify/hydrogen-react';
import mobileClasses from '../navigation/MobileNavBar/MobileNavBar.module.css';

export const ShoppingCartButton = observer(
  ({ isMobile = false }: { isMobile?: boolean }) => {
    const { t } = useTranslation();
    const store = useShopStore();
    const { lines } = useCart();
    const isDisabled = !lines || lines?.length === 0;

    return (
      <>
        <ShoppingCartDrawer
          opened={store.showCartDrawer}
          toggle={store.toggleCartDrawer}
        />
        <Button
          className={isMobile ? mobileClasses.button : classes.button}
          variant="white"
          justify="flex-start"
          size="lg"
          disabled={isDisabled}
          bg={isMobile ? '#F7F6F1' : 'transparent'}
          leftSection={
            <Indicator
              size={20}
              offset={10}
              inline
              label={lines?.length}
              color="#EB0037"
              disabled={!lines?.length}
            >
              <MyIcon
                name={'shopping_cart'}
                className={classes.icon}
                style={{ fill: isDisabled ? '#bbb' : '#000', marginTop: 6 }}
              />
            </Indicator>
          }
          onClick={store.toggleCartDrawer}
        >
          {!isMobile && (
            <Text c="#000" className={classes.navBtnText}>
              {t('Buttons.ShoppingCart')}
            </Text>
          )}
        </Button>
      </>
    );
  },
);

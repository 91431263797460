import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../../util/setupMobX';
import { type GetPodcastsResDto } from '@web/common';
import { getPodcasts } from '../../request/authenticated-requests/podcasts';

export class PodcastListStore {
  @observable public loading: boolean = true;
  @observable public podcastList: GetPodcastsResDto = [];

  constructor() {
    makeObservable(this);
  }

  @action public getPodcasts() {
    this.loading = true;
    getPodcasts()
      .then((podcastList) => {
        runInAction(() => {
          this.podcastList = podcastList;
          this.loading = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }
}

const { provider, useStore } = setupMobX<PodcastListStore>();
export const usePodcastListStore = useStore;
export const PodcastListStoreProvider = provider;

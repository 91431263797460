import { FeatureFlag, RealmRole } from 'apps/common/src/enum';
import { Routes } from '../../../route/Routes';
import { getFeature } from '../../../util/getFeature';
import { CohortType } from '@prisma/client';

export const enum NavTitles {
  CheckParticipant = 'CheckParticipant',
  PausedParticipants = 'PausedParticipants',
  Statistics = 'Statistics',
  FilterUsers = 'Filter Users',
  Home = 'Home',
  Shop = 'Shop',
  Orders = 'Orders',
  Consumption = 'Consumption',
  Profile = 'Profile',
}

const shop =
  getFeature(FeatureFlag.Shop, [
    {
      iconName: 'shopping_bag',
      text: NavTitles.Shop,
      role: [RealmRole.Participant],
      cohortType: [CohortType.LIMITED_ACCESS, CohortType.ACCESS],
      route: `${Routes.Study}/${Routes.Shop}`,
    },
    {
      iconName: 'sell',
      text: NavTitles.Orders,
      role: [RealmRole.ShopEmployee, RealmRole.StudyOrganizer],
      route: `${Routes.Study}/${Routes.OpenShopOrders}`,
    },
  ]) ?? [];

export const navItems = [
  {
    iconName: 'clipboard',
    text: NavTitles.CheckParticipant,
    role: [RealmRole.StudyOrganizer, RealmRole.ShopEmployee],
    route: Routes.Study,
  },
  {
    iconName: 'warning_fill',
    text: NavTitles.PausedParticipants,
    role: [RealmRole.StudyOrganizer],
    route: `${Routes.Study}/${Routes.PausedUsers}`,
  },
  {
    iconName: 'bar_chart',
    text: NavTitles.Statistics,
    role: [RealmRole.StudyOrganizer],
    route: `${Routes.Study}/${Routes.Statistics}`,
  },
  {
    iconName: 'clock',
    text: NavTitles.FilterUsers,
    role: [RealmRole.StudyOrganizer],
    route: `${Routes.Study}/${Routes.FilterUsers}`,
  },
  {
    iconName: 'home',
    text: NavTitles.Home,
    role: [RealmRole.Participant],
    route: Routes.Study,
  },
  ...shop,
  {
    iconName: 'bar_chart',
    text: NavTitles.Consumption,
    role: [RealmRole.Participant],
    cohortType: [CohortType.LIMITED_ACCESS],
    route: `${Routes.Study}/${Routes.Konsum}`,
  },
  {
    iconName: 'person',
    text: NavTitles.Profile,
    role: [RealmRole.Participant],
    cohortType: [CohortType.LIMITED_ACCESS, CohortType.ACCESS],
    route: `${Routes.Study}/${Routes.Profile}`,
  },
] as unknown as NavItem[];

type NavItem = {
  iconName: string;
  text: string;
  role: RealmRole[];
  route: string;
  cohortType?: CohortType[];
};

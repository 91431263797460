import { Flex, Button, Radio, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MyIcon } from '../../../../../component';
import { BaseModal } from '../../../../../component/userStatus/dialogs';
import classes from './Radio.module.css';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useKeycloakStore, useUserMeStore } from '../../../../../store';
import { Language } from '@prisma/client';
import { RealmRole } from '@web/common';

const availableLanguages = [
  {
    language: Language.de,
    label: 'Deutsch',
  },
  {
    language: Language.en,
    label: 'English',
  },
];

export const LanguagePicker = observer(() => {
  const userMeStore = useUserMeStore();
  const kcStore = useKeycloakStore();
  const realmRoles = useMemo(() => {
    return kcStore.realmRoleList || [];
  }, [kcStore]);
  const { t, i18n } = useTranslation('dialogs');
  const [opened, { toggle, close }] = useDisclosure(false);
  const [value, setValue] = useState<Language>(
    userMeStore.myInfo?.language ||
      (i18n.language.toLocaleLowerCase() as Language),
  );

  const isUserLoggedIn = kcStore.authenticated;

  const changeLanguage = () => {
    void i18n.changeLanguage(value);
    if (isUserLoggedIn && realmRoles.includes(RealmRole.Participant)) {
      userMeStore.setMyInfo({ language: value });
    }
    close();
  };

  useEffect(() => {
    if (
      isUserLoggedIn &&
      realmRoles.includes(RealmRole.Participant) &&
      !userMeStore.myInfo
    ) {
      userMeStore.loadMyData();
    }
  }, [realmRoles, userMeStore, isUserLoggedIn]);

  useEffect(() => {
    if (!userMeStore.myInfo) return;
    setValue(userMeStore.myInfo?.language);
  }, [userMeStore.myInfo]);

  return (
    <>
      <Flex p="0px 10px" gap="5px" onClick={toggle}>
        <Text size="14px" lh="25px" fw="500">
          {value.toLocaleUpperCase()}
        </Text>
        <MyIcon name="language" />
      </Flex>

      <BaseModal
        opened={opened}
        onClose={close}
        title=""
        size="500px"
        description={
          <Flex direction="column">
            <Text size="21px" lh="24px" fw="600" c="#202020">
              {t('LanguagePicker.Title')}
            </Text>
            <Radio.Group
              value={value}
              onChange={(value) => setValue(value as Language)}
              name="LanguagePicker"
              w="100%"
            >
              {availableLanguages.map((language) => (
                <Radio
                  classNames={classes}
                  key={language.language}
                  value={language.language}
                  label={language.label}
                />
              ))}
            </Radio.Group>
            <Space h="40px" />

            <Button key="submit" onClick={changeLanguage} w="100%">
              {t('Buttons.Save')}
            </Button>
          </Flex>
        }
        iconColor="#202020"
      />
    </>
  );
});

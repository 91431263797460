import { action, makeObservable, observable, runInAction } from 'mobx';
import { markUserAs } from '../../request/authenticated-requests/user';
import { UserStatus } from '@prisma/client';
import { setupMobX } from '../../util/setupMobX';
import { showErrorNotification } from '../../component/notification';
import type { StammDaten } from '@web/common';
import { ErrorCode } from '@web/common';
import { getCannavigiaData } from './getCannavigiaData';
import { RequestErrorType } from '../../request';

export enum PauseModalType {
  Default = 'Default',
  KontraIndikation = 'KontraIndikation',
}
export class UserStateStore {
  @observable public loading = false;
  @observable public cohortSelection: null | string = null;
  @observable public dispensarySelection: null | string = null;
  @observable public pauseReason: null | string = null;
  @observable public onboardModalOpen = false;
  @observable public pauseModalOpen = false;
  @observable public pauseModalType = PauseModalType.Default;
  @observable public contraIndicationModalOpen = false;
  @observable public contraIndication: null | string = null;
  @observable public deactivateModalOpen = false;
  @observable public notSmokingModalOpen = false;
  @observable public userAlreadyExists = false;

  constructor() {
    makeObservable(this);
  }

  @action public setCohortSelection = (cohortId: null | string) => {
    runInAction(() => {
      this.cohortSelection = cohortId;
    });
  };

  @action public setDispensarySelection = (dispensary: null | string) => {
    runInAction(() => {
      this.dispensarySelection = dispensary;
    });
  };

  @action public setPauseReason = (pauseReason: null | string) => {
    runInAction(() => {
      this.pauseReason = pauseReason;
    });
  };

  @action public setContraIndication = (contraIndication: null | string) => {
    this.contraIndication = contraIndication;
  };

  @action public setOnboardModalOpen = (open: boolean) => {
    this.onboardModalOpen = open;
  };

  @action public setPauseModalOpen = (
    open: boolean,
    type = PauseModalType.Default,
  ) => {
    this.pauseModalOpen = open;
    this.pauseModalType = type;
  };

  @action public setNotSmokingModalOpen = (open: boolean) => {
    this.notSmokingModalOpen = open;
  };

  @action public setContraIndicationModalOpen = (open: boolean) => {
    this.contraIndicationModalOpen = open;
  };

  @action public setDeactivateModalOpen = (open: boolean) => {
    this.deactivateModalOpen = open;
  };

  @action public async setActive(
    userId: string,
    cohortId: string,
    dispensaries: string,
    stammdaten: StammDaten | null,
    cb: () => void,
  ) {
    this.loading = true;
    const cannavigiaData = await getCannavigiaData(
      stammdaten,
      dispensaries,
      this.userAlreadyExists,
    );

    if (!cannavigiaData) {
      showErrorNotification(RequestErrorType.ParseIssue);
      this.loading = false;
      return;
    }

    markUserAs(userId, {
      status: UserStatus.ACTIVE,
      cohortId,
      cannavigiaData: cannavigiaData,
    })
      .then(() =>
        runInAction(() => {
          this.loading = false;
          this.userAlreadyExists = false;
        }),
      )
      .then(() => cb())
      .catch((err) => {
        if (err.errorCode === ErrorCode.CannavigiaHashAndUserAlreadyExists) {
          runInAction(() => {
            this.loading = false;
            this.userAlreadyExists = true;
          });
        } else {
          cb();
          showErrorNotification(err.errorCode);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @action public deactivateUser = (
    userId: string,
    contraIndication: string,
    cb: () => void,
  ) => {
    this.loading = true;
    markUserAs(userId, { status: UserStatus.DEACTIVATED, contraIndication })
      .then(() =>
        runInAction(() => {
          this.loading = false;
        }),
      )
      .then(() => cb())
      .catch(() => showErrorNotification())
      .finally(() => {
        this.loading = false;
      });
  };

  @action public setPaused(
    userId: string,
    pauseReason: string,
    cb: () => void,
  ) {
    this.loading = true;
    markUserAs(userId, { status: UserStatus.PAUSED, pauseReason })
      .then(() =>
        runInAction(() => {
          this.loading = false;
        }),
      )
      .then(() => cb())
      .catch(() => showErrorNotification())
      .finally(() => {
        this.loading = false;
      });
  }
}

const { provider, useStore } = setupMobX<UserStateStore>();
export const useUserStateStore = useStore;
export const UserStateStoreProvider = provider;

import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Progress,
  Stepper,
  Text,
} from '@mantine/core';
import { observer } from 'mobx-react';
import { useDisclosure } from '@mantine/hooks';
import { BaseModal } from '../../../../../component/userStatus/dialogs';
import { MyIcon } from '../../../../../component';
import { useTranslation } from 'react-i18next';
import { ConsumptionStatistics } from './steps/ConsumptionStatistics';
import { QuestionYourConsumption } from './steps/QuestionYourConsumption';
import { SubmitChanges } from './steps/SubmitChanges';
import { ChangeTHCLimit } from './steps/ChangeTHCLimit';
import { useState } from 'react';
import { showSuccessNotification } from '../../../../../component/notification';
import { useConsumptionStore } from '../stores';

const totalStepsLength = 3;

export const ConsumptionDialog = observer(() => {
  const [opened, { open, close }] = useDisclosure(false);
  const [active, setActive] = useState(0);
  const store = useConsumptionStore();
  const { t } = useTranslation('consumption-dialog');
  const isInvalidLimit = store.newLimit % 0.5 !== 0 || store.newLimit === 0;
  const isStepDisabled = active === 2 && isInvalidLimit;

  function makeStep(step: 1 | -1) {
    const nextStep = active + step;

    if (nextStep >= 0 && nextStep <= totalStepsLength) {
      setActive(nextStep);
    }
  }

  function onSuccess() {
    close();
    setActive(0);
    store.getCurrentLimit();
    showSuccessNotification(
      t('SubmitSuccessMsg', { newLimit: store.newLimit }),
    );
  }

  return (
    <>
      <Button id={'button-change-dispensary'} variant="outline" onClick={open}>
        {t('Title.' + (active + 1))}
      </Button>
      <BaseModal
        opened={opened}
        onClose={close}
        title=""
        description=""
        size="500px"
        customHeader={
          <Flex w="100%" justify="space-between" align="center">
            {active > 0 && (
              <ActionIcon
                variant="transparent"
                aria-label="Settings"
                onClick={() => makeStep(-1)}
                pos="absolute"
              >
                <MyIcon name="arrow_back" style={{ fill: 'grey' }} />
              </ActionIcon>
            )}
            <Flex gap="5px" w="100%" justify="center">
              {[1, 2, 3, 4].map((step, index) => {
                const progress = index <= active ? 100 : 0;
                return (
                  <Progress
                    key={index}
                    value={progress}
                    size="md"
                    color="#3D7F53"
                    bg="#D0D0D0"
                    w={index === active ? '20px' : '9px'}
                  />
                );
              })}
            </Flex>
          </Flex>
        }
        children={
          <Box>
            <Text size="16px" lh="24px">
              {t('Step')} {active + 1}/4
            </Text>
            <Text fw="500" lts="-0.5px" lh="25.2px" size="21px">
              {t('Title.' + (active + 1))}
            </Text>
            <Stepper
              active={active}
              onStepClick={() => makeStep(1)}
              styles={{
                stepIcon: { visibility: 'hidden' },
                separator: { visibility: 'hidden' },
                steps: { height: 0 },
              }}
            >
              <Stepper.Step>
                <ConsumptionStatistics />
              </Stepper.Step>
              <Stepper.Step>
                <QuestionYourConsumption />
              </Stepper.Step>
              <Stepper.Step>
                <ChangeTHCLimit />
              </Stepper.Step>
              <Stepper.Completed>
                <SubmitChanges
                  prevStep={() => makeStep(-1)}
                  onSubmit={() => store.updateCurrentThc(onSuccess)}
                />
              </Stepper.Completed>
            </Stepper>
            {active < 3 && (
              <Group justify="center" mt="xl">
                <Button
                  onClick={() => makeStep(1)}
                  w="100%"
                  disabled={isStepDisabled}
                >
                  {t('Next')}
                </Button>
              </Group>
            )}
          </Box>
        }
      />
    </>
  );
});

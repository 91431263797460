import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  getBagSurveyStatus,
  getUserById,
} from '../request/authenticated-requests/user';
import {
  CheckListId,
  ErrorCode,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';
import type { OnboardingDataResDto } from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { RequestErrorType, handleRequestError } from '../request';
import { showErrorNotification } from '../component/notification';
import {
  getParticipantQrCodeContent,
  setParticipantQrCodeContent,
} from '../request/authenticated-requests/participant';
import { DateTime } from 'luxon';
import { CohortType } from '@prisma/client';
import { MyCohortStore } from './MyCohortStore';

/**
 * this is an example of a store, that could be closer to the needed place, but then it is always rerendered (if done wrong) and the store is recreated
 * => improvement
 */
export class ParticipantDataStore {
  @observable public data: OnboardingDataResDto | null = null;
  @observable public participantName: string | null = null;
  @observable public isLoading = false;
  @observable public qrCodeContent: string | null = null;
  @observable public filledOutBagSurvey: boolean = false;
  @observable public bagSurveyLink: string | null = null;

  @computed public get myCohortStartDate(): DateTime | null {
    return this.myCohortStore.cohort?.cohort
      ? DateTime.fromJSDate(this.myCohortStore.cohort?.cohort.startDate)
      : null;
  }
  @computed public get myCohortType(): CohortType | null {
    return this.myCohortStore.cohort?.cohort
      ? this.myCohortStore.cohort?.cohort.cohortType
      : null;
  }

  constructor(private readonly myCohortStore: MyCohortStore) {
    makeObservable(this);
  }

  @computed public get stammdaten() {
    return (
      this.data?.checkListData?.data?.[CheckListId.StammdatenQuestionnaire]
        ?.questionnaireResult || null
    );
  }

  @computed public get cannabisConsumptionData() {
    return (
      this.data?.checkListData?.data?.[
        CheckListId.CannabisConsumptionQuestionnaire
      ]?.questionnaireResult || null
    );
  }

  @action public getParticipantName() {
    if (!this.stammdaten) return 'TBD';

    const name =
      this.stammdaten[StammdatenQuestionnaireQuestionKey.Name] +
      ' ' +
      this.stammdaten[StammdatenQuestionnaireQuestionKey.Surname];

    return name;
  }

  @action public hasStammdaten() {
    return !!this.stammdaten;
  }

  @action public hasCannabisConsumptionData() {
    return !!this.cannabisConsumptionData;
  }

  @action public getParticipantSex() {
    if (!this.stammdaten) return null;
    return this.stammdaten.sex;
  }

  @action public getStammDaten() {
    return this.stammdaten;
  }

  @action public reset() {
    runInAction(() => {
      this.data = null;
    });
  }

  @action public reload() {
    runInAction(() => {
      if (!this.data) return;
      this.retrieveUserInformation(this.data.id);
    });
  }

  @action public getParticipantQrCodeContent() {
    getParticipantQrCodeContent()
      .then((response) =>
        runInAction(() => (this.qrCodeContent = response.qrCodeContent)),
      )
      .catch((error) => handleRequestError(error));
  }

  @action public async setParticipantQrCodeContent(
    participantId: string,
    qrCodeContent: string,
  ) {
    try {
      await setParticipantQrCodeContent(participantId, {
        'qr-code-content': qrCodeContent,
      });
    } catch (error) {
      handleRequestError(error);
    }
  }

  @action public retrieveUserInformation(userId: string) {
    if (this.isLoading) return;
    this.isLoading = true;

    getUserById(userId)
      .then((response) => {
        runInAction(() => {
          this.data = response;
          this.isLoading = false;
          this.participantName = this.getParticipantName();
        });
      })
      .catch((error) => {
        handleRequestError(
          error,
          [
            RequestErrorType.ParseIssue,
            () => showErrorNotification(RequestErrorType.ParseIssue),
          ],
          [
            ErrorCode.RecordNotFound,
            () => showErrorNotification(ErrorCode.RecordNotFound),
          ],
          [
            ErrorCode.NoOnboardingAppointmentFound,
            () => showErrorNotification(ErrorCode.NoOnboardingAppointmentFound),
          ],
        );
        runInAction(() => {
          this.data = null;
          this.isLoading = false;
        });
      });
  }

  @action public getBagSurveyStatus(userId: string) {
    getBagSurveyStatus(userId)
      .then((response) => {
        runInAction(() => {
          this.filledOutBagSurvey = response.filledOutBagSurvey;
          this.bagSurveyLink = response.surveyLink;
        });
      })
      .catch((error) =>
        handleRequestError(error, [
          ErrorCode.RecordNotFound,
          () => showErrorNotification(ErrorCode.RecordNotFound),
        ]),
      );
  }

  @action public getMyCohort() {
    this.myCohortStore.loadMyCohort();
  }
}

const { provider, useStore } = setupMobX<ParticipantDataStore>();
export const useParticipantDataStore = useStore;
export const ParticipantDataProvider = provider;

import { Flex, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '../../navigation';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { useWaitingStateStore } from '../../../../store';
import { useWindowSize } from '../../../../hooks';
import { Loading } from '../../../../component';
import { THCAmountCard } from '../home-screen/components/THCAmountCard';
import { DiaryList } from './components/DiaryList';
import { DiaryDialog } from './DiaryDialog/DiaryDialog';
import { DiaryStore, DiaryStoreProvider } from './stores/DiaryStore';
import { Podcasts, PodcastListVersion } from '../podcast/Podcasts';
import { ObtainablePoints } from '../../../../component/ObtainablePoints';

export const ConsumptionScreen = observer(() => {
  const isSmallScreen = useWindowSize(500);
  const { t } = useTranslation('consumption');

  const store = useWaitingStateStore();
  const isLoading = store.loadingAccessState;

  if (isLoading) return <Loading />;

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={20}
        bg="#fff"
        align="flex-start"
        p={isSmallScreen ? 20 : 40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="38px">
          {t('Consumption')}
        </Text>
        <THCAmountCard />
        <Podcasts version={PodcastListVersion.Short} />
        <Text fw="600" size="21px" lh="24px">
          {t('Diary')}
        </Text>
        <Text c="#4A4A48">{t('DiaryDescription')}</Text>

        <DiaryStoreProvider store={new DiaryStore()}>
          <Flex w="100%" justify="space-between">
            <ObtainablePoints points={50} />
            <DiaryDialog />
          </Flex>

          <DiaryList />
        </DiaryStoreProvider>
      </Flex>
    </BaseLayout>
  );
});

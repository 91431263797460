import { Button, Flex, Space, Text } from '@mantine/core';
import { ProductDto } from '@web/common';
import placeholder from '../../../../../assets/placeholder.svg';
import { Card } from '../../../../../component';
import { useCart } from '@shopify/hydrogen-react';
import { getTitleAndDescriptionFromProduct } from '../utils/getTitleAndDescriptionFromProduct';
import { FeatureAccess } from '../../../../../util/FeatureAccess';
import { FeatureFlag } from '@web/common';

export const Product = ({ product }: { product: ProductDto }) => {
  const { linesAdd } = useCart();

  const { title, description } = getTitleAndDescriptionFromProduct(
    product.title,
  );

  return (
    <Card style={{ padding: 20, height: '100%' }}>
      <Flex direction="column" gap="5px" justify="space-between" h="100%">
        <Flex direction="column" align="center" gap="5px">
          <img
            src={product.imageUrl ?? placeholder}
            alt="product"
            height={100}
          />
          <Space h="xs" />
          <Text fw="600" w="100%" size="16px" lh="24px">
            {title}
          </Text>
          <Text size="12px" lh="18px" c="#4A4A48" w="100%">
            {description}
          </Text>
        </Flex>

        <Flex w="100%" justify="space-between" align="center">
          <Text>{product.price} CHF</Text>
          <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
            <Button
              size="xs"
              w="fit-content"
              onClick={() => linesAdd([{ merchandiseId: product.variantId }])}
            >
              +
            </Button>
          </FeatureAccess>
        </Flex>
      </Flex>
    </Card>
  );
};

import { Flex, Text } from '@mantine/core';
import { MyIcon } from './icon';

export enum ObtainablePointsStatus {
  Unavailable,
  Available,
}

export const ObtainablePoints = ({
  points,
  status = ObtainablePointsStatus.Available,
}: {
  points: number;
  status?: ObtainablePointsStatus;
}) => {
  const color = (() => {
    switch (status) {
      case ObtainablePointsStatus.Available:
        return '#DA3448';
      case ObtainablePointsStatus.Unavailable:
        return '#7F898F';
    }
  })();

  return (
    <Flex gap="5px" align="center">
      <Text c={color} size="14px" lh="14px">
        +{points}
      </Text>
      <MyIcon name="local_activity" style={{ fill: color, width: '20px' }} />
    </Flex>
  );
};

import { Box, Flex, Space, Text } from '@mantine/core';
import { CheckListId, StammdatenQuestionnaireQuestionKey } from '@web/common';
import { getSexQuestion } from '../../../view/authenticated/participant/stammdaten-questionnaire/stammdaten-questionnaire-specification';
import { MyIcon } from '../../icon';
import { observer } from 'mobx-react';
import { useParticipantDataStore } from '../../../store';
import { InfoItem } from '../../dataCards';
import classes from './ParticipantIdentification.module.css';
import { useParticipantData } from '../hooks/useParticipantData';
import { useTranslation } from 'react-i18next';

export const PersonalData = observer(() => {
  const participantDataStore = useParticipantDataStore();
  const { userInformation } = useParticipantData();
  const { t } = useTranslation('stammdaten-questionnaire');
  const { SwissNationality, HeimatOrt, GeburtsOrt } =
    StammdatenQuestionnaireQuestionKey;

  if (!userInformation)
    return (
      <Flex className={classes.noDataBox}>
        <Text ta="center">
          Es sind bisher keine Daten zu diesem Studienteilnehmer vorhanden.
        </Text>
      </Flex>
    );

  const stammdaten =
    userInformation.checkListData?.data?.[CheckListId.StammdatenQuestionnaire]
      ?.questionnaireResult;

  const nameAndGender = (() => {
    const name = participantDataStore.getParticipantName();
    const sex = participantDataStore.getParticipantSex();

    const genderText =
      getSexQuestion(t).choices.find((question) => question.value === sex)
        ?.text ?? 'Unbekannt';
    return (
      <Flex gap="10px">
        <Text className={classes.nameText}>{name}</Text>
        <Text className={classes.baseText} fs="italic">
          ({genderText})
        </Text>
      </Flex>
    );
  })();

  const studienCodeAndAHVNummer = (() => {
    if (!stammdaten) return null;

    return (
      <Flex direction="column">
        <Flex gap="5px">
          <Text className={classes.baseText} w="130px">
            Studiencode:
          </Text>
          <Text size="16px" lh="24px" fw="500">
            {userInformation?.userSearchID}
          </Text>
        </Flex>

        <Flex gap="5px" justify="left">
          <Text className={classes.baseText} w="130px">
            AHV-Nummer:
          </Text>
          <Text size="16px" lh="24px" fw="500">
            {stammdaten[StammdatenQuestionnaireQuestionKey.AHVNumber]}
          </Text>
        </Flex>
      </Flex>
    );
  })();

  const addressAndBirthdate = (() => {
    const email = participantDataStore.data?.email;
    const phoneNumber = participantDataStore.data?.phoneNumber;

    return (
      <Flex direction="column" c="red" gap="2px">
        {stammdaten && (
          <InfoItem
            icon={
              <MyIcon name="location" style={{ fill: '#4a4a48', height: 24 }} />
            }
            customText={
              <Text className={classes.baseText}>
                {stammdaten[StammdatenQuestionnaireQuestionKey.Address]}
              </Text>
            }
          />
        )}
        {stammdaten && (
          <InfoItem
            icon={
              <MyIcon name="home" style={{ fill: '#4a4a48', height: 24 }} />
            }
            customText={
              <Text className={classes.baseText}>
                {
                  stammdaten[
                    stammdaten[SwissNationality] ? HeimatOrt : GeburtsOrt
                  ]!
                }
              </Text>
            }
          />
        )}
        <InfoItem
          icon={<MyIcon name="mail" style={{ fill: '#4a4a48', height: 24 }} />}
          customText={<Text className={classes.baseText}>{email}</Text>}
        />
        <InfoItem
          icon={<MyIcon name="phone" style={{ fill: '#4a4a48', height: 24 }} />}
          customText={
            <Text lh="28px" className={classes.baseText}>
              {phoneNumber ?? '-'}
            </Text>
          }
        />
        {stammdaten && (
          <InfoItem
            icon={
              <MyIcon name="cake" style={{ fill: '#4a4a48', height: 24 }} />
            }
            customText={
              <Text className={classes.baseText}>
                {stammdaten[StammdatenQuestionnaireQuestionKey.BirthDate]}
              </Text>
            }
          />
        )}
      </Flex>
    );
  })();

  return (
    <Box>
      {nameAndGender}
      <Space h="8px" />
      {studienCodeAndAHVNummer}
      <Space h="16px" />
      {addressAndBirthdate}
    </Box>
  );
});

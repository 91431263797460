import {
  CheckboxProps,
  Flex,
  Checkbox,
  Button,
  Accordion,
  Text,
} from '@mantine/core';
import { MyIcon } from '../../../../component';
import classes from './OnboardingChecklist.module.css';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';

export const BaseItem = observer(
  ({
    index,
    item,
    title,
    handleTick,
    changeableWhenDone = true,
    showChevron = true,
    deactivated = false,
  }: {
    index: number;
    item: {
      done: boolean;
    };
    title: string;
    handleTick: () => void;
    changeableWhenDone?: boolean;
    showChevron?: boolean;
    deactivated?: boolean;
  } & PropsWithChildren) => {
    const alreadyAnswered = item.done;

    const CheckboxIcon: CheckboxProps['icon'] = ({ ...others }) => (
      <MyIcon
        name="check"
        {...others}
        style={{ height: 22, width: 22, fill: '#fff' }}
      />
    );

    const accordionProps = {
      key: 'accordion-control',
      styles: {
        chevron: { display: showChevron ? 'block' : 'none' },
      },
    };

    const isDisabled = !changeableWhenDone && alreadyAnswered;
    const commonProps = {
      disabled: deactivated || isDisabled,
      color: '#3D7F53',
    };

    const checkboxProps = {
      ...commonProps,
      styles: { label: { fontSize: 16 } },
      size: 'md',
      icon: CheckboxIcon,
      checked: item.done,
      onChange: handleTick,
    };

    const stepperProps = {
      ...commonProps,
      bg: deactivated ? '' : isDisabled || item.done ? '#3D7F53' : '#fff',
      variant: item.done ? 'filled' : 'outline',
      radius: 'xl',
      onClick: handleTick,
    };

    return (
      <Flex gap="10px" align="center">
        {index === undefined ? (
          <Checkbox {...checkboxProps} />
        ) : (
          <Button {...stepperProps} className={classes.stepperButton}>
            {item.done ? (
              <MyIcon
                name="check"
                style={{
                  height: 22,
                  width: 22,
                  fill: deactivated ? '#7F898F' : '#fff',
                }}
              />
            ) : (
              <Text
                c={deactivated ? '#7F898F' : '#3D7F53'}
                className={classes.stepIndex}
              >
                {(index ?? 0) + 1}
              </Text>
            )}
          </Button>
        )}
        <Accordion.Control
          {...accordionProps}
          className={classes.accordion}
          disabled={deactivated}
        >
          <Text lh="20px">{title}</Text>
        </Accordion.Control>
      </Flex>
    );
  },
);

import { keycloakStore } from './keycloakStore';
import {
  CohortStore,
  CohortStoreProvider,
  DispensaryStore,
  DispensaryStoreProvider,
  EntryQuestionnaireStore,
  EntryQuestionnaireStoreProvider,
  EventStore,
  EventStoreProvider,
  GoogleAnalyticsProvider,
  GoogleAnalyticsStore,
  KeycloakProvider,
  OnboardingChecklistStore,
  OnboardingChecklistStoreProvider,
  ParticipantDataProvider,
  ParticipantDataStore,
  QuestionnaireEvaluationStore,
  QuestionnaireEvaluationStoreProvider,
  SearchUserStore,
  SearchUserStoreProvider,
  SnapPixelProvider,
  SnapPixelStore,
  UserMeStore,
  UserMeStoreProvider,
  UserStateStore,
  UserStateStoreProvider,
  WaitingStateStore,
  WaitingStateStoreProvider,
} from './store';
import { StrictMode } from 'react';
import { MyCohortStore, MyCohortStoreProvider } from './store/MyCohortStore';
import {
  CohortEndingSoonStore,
  CohortEndingSoonStoreProvider,
} from './store/CohortEndingSoonStore';
import {
  ViewParticipantOnboardingProvider,
  ViewParticipantOnboardingStore,
} from './store/ViewParticipantOnboardingStore';
import { ExportStore, ExportStoreProvider } from './store/ExportStore';
import {
  ConsumptionStore,
  ConsumptionStoreProvider,
} from './view/authenticated/participant/hrplus';

/**
 * Some stores react allergic to strict mode. This component provides them.
 *
 * e.g. KeycloakProvider needs to be outside StrictMode, otherwise we have an infinite loop of loading
 *
 * @param children
 * @constructor
 */
const NonStrictStores = ({ children }: { children: JSX.Element }) => {
  return (
    <KeycloakProvider store={keycloakStore}>
      <SnapPixelProvider store={new SnapPixelStore()}>
        <GoogleAnalyticsProvider store={new GoogleAnalyticsStore()}>
          {children}
        </GoogleAnalyticsProvider>
      </SnapPixelProvider>
    </KeycloakProvider>
  );
};

const StrictStores = ({ children }: { children: JSX.Element }) => {
  return <StrictMode>{children}</StrictMode>;
};

const myCohortStore = new MyCohortStore(keycloakStore);
/**
 * Some stores, or rather the data that they provide, are needed for routing.
 * We hoist them up, because they might need special dependencies to work and are likely to stay
 * global, because of the routing.
 *
 * Examples:
 * - keycloak is needed for routing, since it provides the user's role, and depending on that, we add or remove routes
 * - myCohortStore is needed, because depending on the cohorts startDate and type, we add or remove routes
 *
 * @param children
 * @constructor
 */
const RoutingStores = ({ children }: { children: JSX.Element }) => {
  return (
    <MyCohortStoreProvider store={myCohortStore}>
      {children}
    </MyCohortStoreProvider>
  );
};

/**
 * Global application logic stores.
 *
 * TODO https://quickbird.atlassian.net/browse/SCC-520
 * Listing candidates, which should likely be pulled further down into the tree, closer to the components:
 * - EntryQuestionnaireStore
 * - SearchUserStore
 * - CohortEndingSoonStore
 * - OnboardingChecklistStore (trickier: the checklist member is used in a lot of places, maybe split up?) => own ticket
 * - EventStore
 * - ViewParticipantOnboardingStore
 * - ParticipantDataStore: too big, needs to be split up and refactored => own ticket
 * - UserStateStore: could possibly be pulled down, and instantiated in a few places => own ticket
 * - ExportStore
 * - QuestionnaireEvaluationStore
 * - CohortStore: could possibly be pulled down, and instantiated in a few places => own ticket
 * - WaitingStateStore
 *
 * Confirmed rightly global:
 * - ConsumptionStore
 * - UserMeStore
 * - CohortStore
 * - DispensaryStore
 *
 * @param children
 * @constructor
 */
const AppStores = ({ children }: { children: JSX.Element }) => {
  return (
    <EntryQuestionnaireStoreProvider store={new EntryQuestionnaireStore()}>
      <SearchUserStoreProvider store={new SearchUserStore()}>
        <UserMeStoreProvider store={new UserMeStore()}>
          <CohortStoreProvider store={new CohortStore()}>
            <CohortEndingSoonStoreProvider store={new CohortEndingSoonStore()}>
              <DispensaryStoreProvider store={new DispensaryStore()}>
                <OnboardingChecklistStoreProvider
                  store={new OnboardingChecklistStore()}
                >
                  <EventStoreProvider store={new EventStore()}>
                    <WaitingStateStoreProvider
                      store={new WaitingStateStore(myCohortStore)}
                    >
                      <ViewParticipantOnboardingProvider
                        store={new ViewParticipantOnboardingStore()}
                      >
                        <ParticipantDataProvider
                          store={new ParticipantDataStore(myCohortStore)}
                        >
                          <UserStateStoreProvider store={new UserStateStore()}>
                            <ExportStoreProvider store={new ExportStore()}>
                              <QuestionnaireEvaluationStoreProvider
                                store={new QuestionnaireEvaluationStore()}
                              >
                                <ConsumptionStoreProvider
                                  store={new ConsumptionStore()}
                                >
                                  {children}
                                </ConsumptionStoreProvider>
                              </QuestionnaireEvaluationStoreProvider>
                            </ExportStoreProvider>
                          </UserStateStoreProvider>
                        </ParticipantDataProvider>
                      </ViewParticipantOnboardingProvider>
                    </WaitingStateStoreProvider>
                  </EventStoreProvider>
                </OnboardingChecklistStoreProvider>
              </DispensaryStoreProvider>
            </CohortEndingSoonStoreProvider>
          </CohortStoreProvider>
        </UserMeStoreProvider>
      </SearchUserStoreProvider>
    </EntryQuestionnaireStoreProvider>
  );
};

export const GlobalStores = ({ children }: { children: JSX.Element }) => {
  return (
    <NonStrictStores>
      <StrictStores>
        <RoutingStores>
          <AppStores>{children}</AppStores>
        </RoutingStores>
      </StrictStores>
    </NonStrictStores>
  );
};

import { CSSProperties, Flex, FlexProps } from '@mantine/core';
import classes from './Card.module.css';

export const Card = ({
  children,
  flexProps,
  style,
  className,
  size,
}: {
  children: JSX.Element | JSX.Element[];
  flexProps?: FlexProps;
  style?: CSSProperties;
  className?: string;
  size?: 'small' | 'medium';
}) => {
  return (
    <Flex
      className={`${size === 'small' ? classes.smallCard : classes.card} ${className}`}
      {...flexProps}
      style={style}
      direction="column"
    >
      {children}
    </Flex>
  );
};

import { Box, Space, Text } from '@mantine/core';
import { InfoItem } from '../../dataCards';
import { MyIcon } from '../../icon';
import { useParticipantDataStore } from '../../../store';
import { UserStatus } from '@prisma/client';
import { translateValue } from '../../../util/translateLabels';
import { userStatusColors, userStatusIcons } from '@web/common';
import classes from './ParticipantIdentification.module.css';
import { UserStatusInfo } from './UserStatusInfo';
import { useTranslation } from 'react-i18next';
import { UpdateQRCodeButton } from '../../../view/authenticated/participant/update-qr-code/UpdateQRCodeButton';
import { ResponsibleUser } from './responsible-user/ResponsibleUser';

export const ParticipantStateInformation = () => {
  const participantDataStore = useParticipantDataStore();
  const status = participantDataStore.data?.status;
  const { t } = useTranslation('organizer-dashboard');

  if (!participantDataStore.data || !status || status === UserStatus.OPEN)
    return;

  const {
    pauseReason,
    contraIndication,
    contraIndicationDate,
    Participant,
    status: userStatus,
  } = participantDataStore.data;
  const icon = translateValue(status, userStatusIcons);
  const color = translateValue(status, userStatusColors);
  const text = t('UserStatusEnum.' + status);
  const { description, message } = UserStatusInfo(
    status,
    pauseReason,
    contraIndication,
    contraIndicationDate,
    Participant,
  );

  return (
    <Box>
      <InfoItem
        icon={<MyIcon name={icon} style={{ fill: color }} />}
        customText={
          <Text className={classes.baseText} c={color}>
            {text}
          </Text>
        }
      />
      {status === UserStatus.ACTIVE && <ResponsibleUser />}
      {status !== UserStatus.ACTIVE && (
        <Text className={classes.baseText} c={color}>
          {description}
        </Text>
      )}
      <Space h="16px" />
      {message}
      <Space h="16px" />
      {userStatus === UserStatus.ACTIVE && <UpdateQRCodeButton />}
    </Box>
  );
};

import { Flex, Text } from '@mantine/core';
import { Card, MyIcon } from '../../../../../../component';
import { observer } from 'mobx-react';
import { useContestStore } from '../../../../../../store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../route/Routes';

export const LoyalityPointInfoUI = observer(() => {
  const contestStore = useContestStore();
  const navigate = useNavigate();
  const funds = contestStore.funds;
  const { t } = useTranslation('homescreen');

  useEffect(() => {
    contestStore.loadFunds();
  }, [contestStore]);

  return (
    <div onClick={() => navigate(Routes.Points)} style={{ cursor: 'pointer' }}>
      <Card size="small">
        <Flex gap="10px">
          <Flex gap="5px" align="center">
            <MyIcon
              name="local_activity"
              style={{ fill: '#DA3448', width: '20px' }}
            />
            <Text c="#DA3448" size="14px" lh="14px">
              {funds}
            </Text>
          </Flex>

          <Text>{t('AvailablePoints')}</Text>
        </Flex>
      </Card>
    </div>
  );
});

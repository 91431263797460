import { observer } from 'mobx-react';
import { useKeycloakStore } from '../../../store';
import { Button, Text } from '@mantine/core';
import classes from './Layout.module.css';
import { MyIcon } from '../../../component';
import { useTranslation } from 'react-i18next';

export const LogoutButton = observer(
  ({ smallIcon }: { smallIcon: boolean }) => {
    const kc = useKeycloakStore();
    const { t } = useTranslation();

    return (
      <Button
        className={classes.button}
        variant={smallIcon ? 'outline' : 'white'}
        justify="flex-start"
        size="lg"
        leftSection={<MyIcon name={'logout'} className={classes.icon} />}
        onClick={() => kc.logout()}
      >
        <Text className={classes.navBtnText}>{t('Buttons.Logout')}</Text>
      </Button>
    );
  },
);

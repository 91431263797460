import { Flex, Space, Text } from '@mantine/core';
import { CohortType } from '@prisma/client';
import { FeatureFlag } from '@web/common';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '../../navigation';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { useUserMeStore, useWaitingStateStore } from '../../../../store';
import { useWindowSize } from '../../../../hooks';
import { StudyCardWrapper, ThcLimitWarning } from './components';
import { Loading, LogoutButton } from '../../../../component';
import { OpenOrders } from '../shop/components/OpenOrders';
import { FeatureAccess } from '../../../../util/FeatureAccess';

const canAccessStudyCard = [
  CohortType.ACCESS,
  CohortType.LIMITED_ACCESS,
] as CohortType[];

export const ProfileScreen = observer(() => {
  const isSmallScreen = useWindowSize(500);
  const myInfoStore = useUserMeStore();
  const { t } = useTranslation('profilescreen');
  const canSeeStudyCard = canAccessStudyCard.includes(
    myInfoStore.myInfo?.Participant?.cohortType ?? CohortType.NONE,
  );
  const waitingStateStore = useWaitingStateStore();
  const isLoading = waitingStateStore.loadingAccessState;

  if (isLoading) return <Loading />;

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={20}
        bg="#fff"
        align="flex-start"
        p={isSmallScreen ? 20 : 40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="38px">
          {t('Profile')}
        </Text>
        <ThcLimitWarning />
        {canSeeStudyCard && <StudyCardWrapper />}
        <Space h="lg" />
        <Text fw="600" size="21px" lh="24px">
          {t('OpenOrders')}
        </Text>
        <Text size="14px" lh="21px">
          {t('SeeOpenOrdersDescription')}
        </Text>
        <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
          <OpenOrders showDate />
        </FeatureAccess>
        <Space h="lg" />
        <Text lh="24px" size="21px" fw="500">
          {t('Settings')}
        </Text>
        <LogoutButton />
      </Flex>
    </BaseLayout>
  );
});

import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { RealmRole, type MyCohortResDto } from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { getMyCohort } from '../request/authenticated-requests/user';
import { KeycloakStore } from './KeycloakStore';

export class MyCohortStore {
  @observable public loading: boolean = false;
  @observable public cohort: null | MyCohortResDto = null;

  constructor(kcStore: KeycloakStore) {
    makeObservable(this);
    autorun(() => {
      if (
        kcStore.authenticated &&
        kcStore.currentRealmRole === RealmRole.Participant
      ) {
        console.debug(`User is authenticated, loading my cohort`);
        this.loadMyCohort();
      }
    });
  }

  @action public loadMyCohort = (successAction?: () => void) => {
    this.loading = true;
    getMyCohort()
      .then((cohort) => {
        runInAction(() => {
          this.cohort = cohort;
          this.loading = false;
          successAction?.();
        });
      })
      .catch((error) => {
        console.error(error);
        runInAction(() => {
          this.loading = false;
        });
      });
  };
}

const { provider, useStore } = setupMobX<MyCohortStore>();
export const useMyCohortStore = useStore;
export const MyCohortStoreProvider = provider;

import { Box, Button, Text } from '@mantine/core';
import classes from './Layout.module.css';
import { MyIcon } from '../../../component';

export const NavItem = ({
  iconName,
  text,
  route,
  onClick,
}: {
  iconName: string;
  text: string | JSX.Element;
  route: string;
  onClick?: () => void;
}) => (
  <Box p="0px 5px">
    <Button
      className={classes.button}
      variant="white"
      justify="flex-start"
      size="lg"
      leftSection={
        <MyIcon
          name={iconName}
          className={classes.icon}
          style={{ fill: '#202020' }}
        />
      }
      onClick={onClick}
      bg={route === window.location.pathname ? '#F1F0E899' : undefined}
    >
      <Text className={classes.navBtnText} style={{ color: '#202020' }}>
        {text}
      </Text>
    </Button>
  </Box>
);

import { MyIcon, InfoItem } from '../../../../component';

export const ContactDetails = () => {
  return (
    <>
      <InfoItem
        icon={<MyIcon name="phone" />}
        text={
          <a style={{ color: '#3D7F53' }} href="tel:0444401121">
            044 440 11 21
          </a>
        }
      />
      <InfoItem
        icon={<MyIcon name="mail" />}
        text={
          <a style={{ color: '#3D7F53' }} href="mailTo:info@pilotversuche.ch">
            info@pilotversuche.ch
          </a>
        }
      />
    </>
  );
};

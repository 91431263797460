import { setupMobX } from '../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getCohorts } from '../request/authenticated-requests/cohort';
import { CohortResDto } from '@web/common';
import { showErrorNotification } from '../component/notification';

export class CohortStore {
  @observable public cohorts: CohortResDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadCohorts = () => {
    getCohorts()
      .then((cohorts) => {
        runInAction(() => {
          this.cohorts = cohorts;
        });
      })
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<CohortStore>();
export const useCohortStore = useStore;
export const CohortStoreProvider = provider;

import { observer } from 'mobx-react';
import { Box, Center, Container, Flex, Text } from '@mantine/core';
import { Loading, Title } from '../../../../component';
import { useWindowSize } from '../../../../hooks';
import { TaskStepper } from './TaskStepper';
import { useEffect } from 'react';
import { useWaitingStateStore } from '../../../../store';
import { getStepperActionButton } from './getStepperActionButton';
import { Header } from '../../../public/navigation';
import { useTranslation } from 'react-i18next';

export const WaitingState = observer(() => {
  const waitingStateStore = useWaitingStateStore();
  const isLoading = waitingStateStore.loadingAccessState;
  const { currentStep, baseLineSurveyLink } = waitingStateStore;
  const isSmallScreen = useWindowSize(600);
  const { t } = useTranslation('waiting-screen');

  useEffect(() => {
    waitingStateStore.loadDataAndCheckAccess();
  }, [waitingStateStore]);

  if (isLoading) return <Loading />;

  return (
    <>
      <Header canLogout />
      <Flex p="40px 20px 0px 20px" gap="30px" justify="center" align="center">
        <Flex w="1000px" justify="space-between" wrap="wrap">
          <Box>
            <Title text={t('Title')} boxPadding="0px" textPadding="0px" />
            <Text maw="600px" m="10px 0px 0px 0px">
              {t('Description')}
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Container fluid p={0}>
        <Center p={isSmallScreen ? '20px 0px' : '20px'}>
          <Flex
            direction="column"
            bg="#fff"
            maw="1000px"
            style={{ borderRadius: isSmallScreen ? 0 : 5 }}
          >
            <Flex m={15} direction="column" p="10px">
              <Text fw="700" size="21px">
                {t('YourCurrentTask')}
              </Text>

              <TaskStepper
                currentAction={getStepperActionButton(
                  currentStep,
                  baseLineSurveyLink,
                )}
              />
            </Flex>
          </Flex>
        </Center>
      </Container>
    </>
  );
});

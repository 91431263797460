import { observer } from 'mobx-react';
import { ShopifyProvider, CartProvider } from '@shopify/hydrogen-react';
import { ShopStore, ShopStoreProvider } from './store/ShopStore';

const storeDomain = process.env['NX_PUBLIC_STOREFRONT_DOMAIN'];
const storefrontToken = process.env['NX_PUBLIC_STOREFRONT_API_TOKEN'];
const storeApiVersion = process.env['NX_SHOPIFY_STORE_API_VERSION'];

export const ShopProvider = observer(
  ({ children }: { children: JSX.Element }) => {
    const shopStore = new ShopStore();

    return (
      <ShopStoreProvider store={shopStore}>
        <ShopifyProvider
          storeDomain={storeDomain ?? 'noDomain'} // empty string is not allowed and will result in a crash
          storefrontToken={storefrontToken ?? 'noToken'} // empty string is not allowed and will result in a crash
          storefrontApiVersion={storeApiVersion ?? '2024-07'}
          countryIsoCode="CH"
          languageIsoCode="DE"
        >
          <CartProvider
            onLineAdd={() => shopStore.setLoadingCart(true)}
            onLineRemove={() => shopStore.setLoadingCart(true)}
            onLineUpdate={() => shopStore.setLoadingCart(true)}
            onLineAddComplete={() => {
              // this happens when an item is added to an existing cart
              shopStore.setCartDrawerOpen(true);
              shopStore.setLoadingCart(false);
            }}
            onLineUpdateComplete={() => shopStore.setLoadingCart(false)}
            onLineRemoveComplete={() => shopStore.setLoadingCart(false)}
            onCreateComplete={() => {
              // this happens when the cart is empty and a new line (product) is added to the cart
              shopStore.setCartDrawerOpen(true);
              shopStore.setLoadingCart(false);
            }}
          >
            {children}
          </CartProvider>
        </ShopifyProvider>
      </ShopStoreProvider>
    );
  },
);

import { Flex, Text } from '@mantine/core';
import { CohortType } from '@prisma/client';
import { cohortTypeTranslation } from '@web/common';
import { translateValue } from '../../../../util/translateLabels';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePickUpStore } from '../../../../../src/view/authenticated/participant/shop/components/PickUp/PickUpStore';
import { observer } from 'mobx-react';
import { useThcStore } from '../../../../../src/store/shopEmployee/ThcStore';

export const AcceptedUserInfoUI = observer(
  (participant: {
    cohortId: string;
    createdAt: Date;
    cannavigiaCode: string;
    cohortType: CohortType;
  }): JSX.Element => {
    const pickUpStore = usePickUpStore();
    const thcStore = useThcStore();
    const { userId } = useParams();
    const { cohortId, createdAt, cannavigiaCode, cohortType } = participant;
    const cohortTypeTranslated = cohortType
      ? translateValue(cohortType, cohortTypeTranslation)
      : '-';

    useEffect(() => {
      if (userId) {
        pickUpStore.getUserDispensary(userId);
        thcStore.loadAvailableThc(userId);
      }
    }, [pickUpStore, thcStore, userId]);

    return (
      <>
        <Flex gap="10px">
          <Text w="150px">Kohorte:</Text>
          <Text fw="500">
            Kohorte {cohortId}
            {' - '}
            {DateTime.fromJSDate(createdAt).toFormat('dd.MM.yyyy')}
          </Text>
        </Flex>
        <Flex gap="10px">
          <Text w="150px">Cannavigia Code:</Text>
          <Text fw="500">{cannavigiaCode}</Text>
        </Flex>
        <Flex gap="10px">
          <Text w="150px">Studiengruppe:</Text>
          <Text fw="500">{cohortTypeTranslated}</Text>
        </Flex>
        <Flex gap="10px">
          <Text w="150px">Ausgabestelle:</Text>
          <Text fw="500">{pickUpStore.dispensary?.name ?? '-'}</Text>
        </Flex>
        <Flex gap="10px">
          <Text w="150px">THC verfügbar:</Text>
          <Text fw="500">
            {thcStore.availableThc?.availableThcThisMonth ?? '-'}
            {thcStore.availableThc?.unit}
          </Text>
        </Flex>
      </>
    );
  },
);

import { observer } from 'mobx-react';
import { useKeycloakStore } from '../../store';
import { ActionIcon, Button, Text } from '@mantine/core';
import { MyIcon } from '../icon';
import { useTranslation } from 'react-i18next';

export const LogoutButton = observer(() => {
  const kc = useKeycloakStore();
  const { t } = useTranslation();

  return (
    <Button
      variant="outline"
      w="100%"
      fw="400"
      onClick={() => kc.logout()}
      maw="200px"
    >
      <Text w="100%" size="16px" lh="18px" ta="center">
        {t('Buttons.LOGOUT')}
      </Text>
    </Button>
  );
});

export const LogoutIconButton = observer(() => {
  const kc = useKeycloakStore();

  return (
    <ActionIcon variant="white" onClick={() => kc.logout()}>
      <MyIcon name="logout" />
    </ActionIcon>
  );
});

import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Progress,
  Stepper,
} from '@mantine/core';
import { observer } from 'mobx-react';
import { useDisclosure } from '@mantine/hooks';
import { BaseModal } from '../../../../../component/userStatus/dialogs';
import { MyIcon } from '../../../../../component';
import { useTranslation } from 'react-i18next';
import { Consumption } from './steps/Consumption';
import { Feelings } from './steps/Feelings';
import { OptionalNotes } from './steps/OptionalNotes';
import { Reasons } from './steps/Reasons';
import { useDiaryDialogStore } from './DiaryDialogStore';
import { showPointsAwardedNotification } from '../../../../../component/notification';
import { useDiaryStore } from '../stores/DiaryStore';
import { CreateDiaryEntryResDto, LoyalityRewardType } from '@web/common';
import { LoyalityPointsLogReason } from '@prisma/client';

const totalStepsLength = 3;

export const ContentWrapper = observer(() => {
  const [opened, { open, close }] = useDisclosure(false);
  const store = useDiaryDialogStore();
  const diaryStore = useDiaryStore();
  const active = store.activeStep;
  const { t } = useTranslation('diary-dialog');

  function makeStep(step: 1 | -1) {
    const nextStep = active + step;

    if (nextStep >= 0 && nextStep <= totalStepsLength)
      store.setActiveStep(nextStep);
  }

  function onSuccess() {
    store.submitDiaryEntry((result: CreateDiaryEntryResDto) => {
      result.loyalityPoints.forEach((pointReward) => {
        if (pointReward.type === LoyalityRewardType.PointsReward) {
          switch (pointReward.reason) {
            case LoyalityPointsLogReason.DiaryEntry: {
              showPointsAwardedNotification(
                pointReward.points,
                pointReward.reason,
              );
              break;
            }
            case LoyalityPointsLogReason.DiaryEntry7DaysInARow: {
              showPointsAwardedNotification(
                pointReward.points,
                pointReward.reason,
              );
              break;
            }
            default:
              break;
          }
        }
      });
      diaryStore.loadDiaryEntries(0);
      closeModal();
    });
  }

  function closeModal() {
    store.resetConsumptionData();
    close();
  }

  return (
    <>
      <Button id={'add-diary-entry'} onClick={open}>
        {t('AddEntry')} +
      </Button>
      <BaseModal
        opened={opened}
        onClose={closeModal}
        title=""
        description=""
        size="400px"
        withCloseButton
        customHeader={
          <Flex w="100%" justify="space-between" align="center">
            {active > 0 && (
              <ActionIcon
                variant="transparent"
                aria-label="Settings"
                onClick={() => makeStep(-1)}
                pos="absolute"
              >
                <MyIcon name="arrow_back" style={{ fill: 'grey' }} />
              </ActionIcon>
            )}
            <Flex gap="5px" w="100%" justify="center">
              {[1, 2, 3, 4].map((step, index) => {
                const progress = index <= active ? 100 : 0;
                return (
                  <Progress
                    key={index}
                    value={progress}
                    size="md"
                    color="#3D7F53"
                    bg="#D0D0D0"
                    w={index === active ? '20px' : '9px'}
                  />
                );
              })}
            </Flex>
          </Flex>
        }
        children={
          <Box>
            <Stepper
              active={active}
              onStepClick={() => makeStep(1)}
              styles={{
                stepIcon: { visibility: 'hidden' },
                separator: { visibility: 'hidden' },
                steps: { height: 0 },
              }}
            >
              <Stepper.Step>
                <Consumption makeStep={() => makeStep(1)} />
              </Stepper.Step>
              <Stepper.Step>
                <Feelings makeStep={() => makeStep(1)} />
              </Stepper.Step>
              <Stepper.Step>
                <Reasons makeStep={() => makeStep(1)} />
              </Stepper.Step>
              <Stepper.Completed>
                <OptionalNotes />
              </Stepper.Completed>
            </Stepper>

            {active === 3 && (
              <Group justify="center" mt="xl">
                <Button onClick={onSuccess} w="100%">
                  {t('Reasons.Save')}
                </Button>
              </Group>
            )}
          </Box>
        }
      />
    </>
  );
});

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@mantine/core';
import {
  PodcastListStore,
  PodcastListStoreProvider,
} from '../../../../store/podcast';
import { PodcastList } from './PodcastList';

export enum PodcastListVersion {
  Short,
  Long,
}

export const Podcasts = observer(
  ({ version }: { version: PodcastListVersion }) => {
    const { t } = useTranslation('podcast');

    const subtitleText = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return t('List.Short.Subtitle');
        }
        case PodcastListVersion.Long: {
          return t('List.Long.Subtitle');
        }
      }
    };

    return (
      <Flex direction={'column'} gap={'0.5em'}>
        <Text fw="500" size="28px" lh="38px">
          {t('List.Short.Title')}
        </Text>
        <Text>{subtitleText()}</Text>
        <PodcastListStoreProvider store={new PodcastListStore()}>
          <PodcastList version={version} />
        </PodcastListStoreProvider>
      </Flex>
    );
  },
);

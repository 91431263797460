import { Flex } from '@mantine/core';
import { FeatureFlag, RealmRole } from '@web/common';
import { useKeycloakStore } from '../../../../store';
import classes from './MobileNavBar.module.css';
import { observer } from 'mobx-react';
import { FeatureAccess } from '../../../../util/FeatureAccess';
import { ShoppingCartButton } from '../ShoppingCartButton';
import useNavItems from '../useNavItems';

export const MobileNavBar = observer(() => {
  const kcStore = useKeycloakStore();
  const currentRole = kcStore.currentRealmRole;
  const navItemElements = useNavItems(true);

  return (
    <Flex className={classes.navBar}>
      <Flex className={classes.innerNavBar}>
        <Flex w="100%" direction="row" justify="space-around">
          {navItemElements}
          <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
            {currentRole === RealmRole.Participant && (
              <ShoppingCartButton isMobile />
            )}
          </FeatureAccess>
        </Flex>
      </Flex>
    </Flex>
  );
});

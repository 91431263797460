import {
  availableThcResDto,
  currentThcResDto,
  thcConsumptionStatisticsResDto,
  ThcLimitReqDto,
  thcLimitResDto,
} from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';

export const getMyCurrentThc = async () => {
  return authenticatedAPI.get(`/api/participant/thc/current`, currentThcResDto);
};

export const getCurrentThcLimit = async () => {
  return authenticatedAPI.get(`/api/participant/thc/limit`, thcLimitResDto);
};

export const getMyThcStatistics = async () => {
  return authenticatedAPI.get(
    `/api/participant/thc/statistics`,
    thcConsumptionStatisticsResDto,
  );
};

export const setMyThcLimit = async (data: ThcLimitReqDto) => {
  return authenticatedAPI.post(
    `/api/participant/thc/limit`,
    data,
    thcLimitResDto,
  );
};

// endpoint for shop employee
export const getAvailableThcForParticipant = async (userId: string) => {
  return authenticatedAPI.get(
    `/api/participant/thc/current/${userId}`,
    availableThcResDto,
  );
};

import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import {
  showErrorNotification,
  showPointsAwardedNotification,
} from '../component/notification';
import {
  completeMission,
  getAvailableMissions,
} from '../request/authenticated-requests/missions';
import { AvailableMission, LoyalityRewardType } from '@web/common';
import { MissionType } from '@prisma/client';

export class MissionStore {
  @observable public missions: AvailableMission[] = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadMissions = () => {
    getAvailableMissions()
      .then((missions) => {
        const streakMission = missions.find(
          (mission) => mission.type === MissionType.RegularQuestionnaireStreak,
        );
        const regularQuestionnaireMission = missions.find(
          (mission) => mission.type === MissionType.RegularQuestionnaire,
        );

        if (streakMission && regularQuestionnaireMission) {
          streakMission.points += regularQuestionnaireMission.points;
        }

        runInAction(() => {
          this.missions = streakMission
            ? missions.filter(
                (mission) => mission.type !== MissionType.RegularQuestionnaire,
              )
            : missions;
        });
      })
      .catch(() => {
        showErrorNotification();
      });
  };

  @action public completeMission = (missionType: MissionType) => {
    completeMission(missionType)
      .then((result) => {
        if (result.type === LoyalityRewardType.PointsReward) {
          setTimeout(() => {
            showPointsAwardedNotification(result.points, result.reason);
          }, 3000);
        }
      })
      .catch(() => {
        showErrorNotification();
      });
  };
}

const { provider, useStore } = setupMobX<MissionStore>();
export const useMissionStore = useStore;
export const MissionStoreProvider = provider;

import { z } from 'zod';

export const productSchemaDto = z.object({
  id: z.string(),
  variantId: z.string(),
  title: z.string(),
  handle: z.string(),
  inventory: z.number(),
  price: z.coerce.number(),
  currencyCode: z.string(),
  imageUrl: z.string().url().nullish(),
  weight: z.object({
    value: z.number(),
    unit: z.string(),
  }),
  thcAmount: z.number(),
  GTIN: z.string(),
});
export type ProductDto = z.infer<typeof productSchemaDto>;

export const productsResDto = z.array(productSchemaDto);
export type ProductsResDto = z.infer<typeof productsResDto>;

import { Flex, Button, Image, Text, TextInput } from '@mantine/core';
import people from '../../../../../assets/cannabis-studie-zuerich-intro.webp';
import classes from '../../LandingPage.module.css';
import { useTranslation } from 'react-i18next';
import { Cooperations } from '../../sections';
import { observer } from 'mobx-react';
import { useEnrollStore } from './EnrollStore';
import { Language } from '@prisma/client';
import { useState } from 'react';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useGoogleAnalyticsStore,
  useSnapPixelStore,
} from '../../../../../store';

export const ShortIntroUI = observer(({ id }: { id: string }) => {
  const enrollStore = useEnrollStore();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const { i18n } = useTranslation();
  const currentLang = i18n.language as Language;
  const { t } = useTranslation('landingpage');
  const [mailOrPhonenumber, setMailOrPhonenumber] = useState<string>('');

  function validateEmailOrPhonenumber(email: string): boolean {
    const validNumber = /^\+[0-9]{9,12}$/;
    const validMail = /\S+@\S+\.\S+/;

    return validMail.test(email) || validNumber.test(email);
  }

  return (
    <Flex
      id={id}
      className={classes.introContainer}
      direction="column"
      justify="center"
      bg="#fff"
    >
      <Flex align="center" wrap="wrap" className={classes.introBox}>
        <Text className={classes.introTitle}>{t('ShortIntro.Title')}</Text>
        <Text size="16px" lh="24px" p="0px 20px" ta="center" maw="780px">
          {t('ShortIntro.Description')}
        </Text>
        <TextInput
          placeholder={t('ShortIntro.EMailAddressOrNumber')}
          onChange={(input) => setMailOrPhonenumber(input.target.value)}
          w="100%"
          maw="400px"
          px="20px"
        />

        <Button
          w="100%"
          maw="360px"
          px="20px"
          disabled={!validateEmailOrPhonenumber(mailOrPhonenumber)}
          loading={enrollStore.loading}
          onClick={() => {
            snapPixelStore.track(SnapEvent.Enroll_Register);
            googleAnalyticsStore.track(GoogleAnalyticsEvent.Enroll_Register);
            enrollStore.enrollUser(mailOrPhonenumber, currentLang);
          }}
        >
          {t('Buttons.Login')}
        </Button>

        <Text>{t('ShortIntro.WeDontSpam')}</Text>
        <Cooperations />
        <Image
          radius="md"
          src={people}
          title="Cannabis Studie Zürich"
          alt="Cannabis Studie Zürich"
          className={classes.introImg}
        />
      </Flex>
    </Flex>
  );
});

import { LoyalityPointsLogReason } from '@prisma/client';
import { z } from 'zod';

export enum LoyalityRewardType {
  NoReward = 'NoReward',
  PointsReward = 'PointsReward',
}

const common = z.object({
  reason: z.nativeEnum(LoyalityPointsLogReason),
});
const noReward = z
  .object({
    type: z.literal(LoyalityRewardType.NoReward),
  })
  .merge(common);
const pointReward = z
  .object({
    type: z.literal(LoyalityRewardType.PointsReward),
    points: z.number(),
  })
  .merge(common);

export const loyalityPointsRewardResDto = z.discriminatedUnion('type', [
  noReward,
  pointReward,
]);
export type LoyalityPointsRewardResDto = z.infer<
  typeof loyalityPointsRewardResDto
>;

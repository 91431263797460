import { observer } from 'mobx-react';
import { useKeycloakStore, useParticipantDataStore } from '../../store';
import { Button, Flex, Text } from '@mantine/core';
import { useEffect } from 'react';
import { useCohortStore } from '../../store';
import { CohortType, UserStatus as UserStatusEnum } from '@prisma/client';
import { allowedStatusChangesByRole } from '@web/common';
import { useUserStateStore } from '../../store';
import { MyIcon } from '../icon';
import {
  OnboardModal,
  PauseModal,
  DeactivateModal,
  ContraIndicationModal,
} from './dialogs';
import { NotSmokingModal } from './dialogs/NotSmokingModal';
import { useDispensaryStore } from '../../store/DispensaryStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChangeDispensaryWrapper as ChangeDispensaryModal } from './dialogs/ChangeDispensaryModal';

export const UserStatusButtons = observer(
  ({
    currentStatus,
    onUserStateUpdated,
  }: {
    currentStatus: UserStatusEnum;
    onUserStateUpdated: () => void;
  }) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const cohortStore = useCohortStore();
    const dispensaryStore = useDispensaryStore();
    const participantDataStore = useParticipantDataStore();
    const hasStammdaten = participantDataStore.hasStammdaten();
    const hasCannabisConsumptionData =
      participantDataStore.hasCannabisConsumptionData();

    const assignedCohort = participantDataStore.data?.Participant?.cohortType;

    const canAcceptUser = hasStammdaten && hasCannabisConsumptionData;
    const isUserActive = currentStatus === UserStatusEnum.ACTIVE;

    const kcStore = useKeycloakStore();
    const roles = kcStore.realmRoleList || [];
    const stateStore = useUserStateStore();

    const { t } = useTranslation('check-participant');

    useEffect(() => {
      if (
        userId &&
        isUserActive &&
        assignedCohort &&
        assignedCohort !== CohortType.NONE
      )
        participantDataStore.getBagSurveyStatus(userId);
    }, [userId, participantDataStore, isUserActive, assignedCohort]);

    useEffect(() => {
      cohortStore.loadCohorts();
      dispensaryStore.loadDispensaries();
    }, [cohortStore, dispensaryStore]);

    const activateButton = (
      <Button
        key="activate"
        disabled={!canAcceptUser}
        onClick={() => stateStore.setOnboardModalOpen(true)}
        rightSection={<MyIcon name="check" style={{ fill: '#fff' }} />}
      >
        {t('Buttons.Accept')}
      </Button>
    );
    const deactivateButton = (
      <Button
        key="deactivate"
        disabled={!canAcceptUser}
        variant="outline"
        onClick={() => stateStore.setDeactivateModalOpen(true)}
        rightSection={
          <MyIcon
            name="close"
            style={{ fill: canAcceptUser ? '#3D7F53' : '#fff' }}
          />
        }
      >
        {isUserActive ? t('Buttons.RemoveParticipant') : t('Buttons.Decline')}
      </Button>
    );
    const pauseButton = (
      <Button
        id={'button-pause'}
        disabled={!canAcceptUser}
        variant="outline"
        onClick={() => stateStore.setPauseModalOpen(true)}
        rightSection={
          <MyIcon
            name="hourglass"
            style={{ fill: canAcceptUser ? '#3D7F53' : '#fff' }}
          />
        }
      >
        {t('Buttons.Pause')}
      </Button>
    );
    const leftVoluntarily = (
      <Button
        id={'button-pause'}
        color="red"
        onClick={() => {
          stateStore.setContraIndication(
            'Teilnehmer ist freiwillig ausgetreten',
          );
          stateStore.setDeactivateModalOpen(true);
        }}
      >
        {t('Buttons.ParticipantLostInterest')}
      </Button>
    );

    const actions = allowedStatusChangesByRole(roles, currentStatus).flatMap(
      (status) => {
        switch (status) {
          case UserStatusEnum.OPEN:
            return [];
          case UserStatusEnum.ACTIVE:
            return [activateButton];
          case UserStatusEnum.PAUSED:
            return [pauseButton];
          case UserStatusEnum.DEACTIVATED:
            return [deactivateButton];
          default:
            return [];
        }
      },
    );

    if (!userId) return;

    return (
      <>
        <OnboardModal userId={userId} onUserStateUpdated={onUserStateUpdated} />
        <PauseModal userId={userId} onUserStateUpdated={onUserStateUpdated} />
        <DeactivateModal
          userId={userId}
          onUserStateUpdated={() => navigate(-1)}
        />
        <ContraIndicationModal
          userId={userId}
          onUserStateUpdated={onUserStateUpdated}
        />
        <NotSmokingModal
          userId={userId}
          onUserStateUpdated={onUserStateUpdated}
        />
        {!canAcceptUser &&
          currentStatus !== UserStatusEnum.DEACTIVATED &&
          currentStatus !== UserStatusEnum.ACTIVE && (
            <Text p="0px 20px">{t('Messages.ButtonsDeactivated')}</Text>
          )}

        <Flex gap="20px" p={isUserActive ? '10px 0px' : '10px 20px'}>
          {currentStatus === UserStatusEnum.ACTIVE && (
            <ChangeDispensaryModal userId={userId} />
          )}
          {actions}
          {currentStatus !== UserStatusEnum.DEACTIVATED && leftVoluntarily}
        </Flex>
      </>
    );
  },
);

import { Flex, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ObtainablePoints } from '../../ObtainablePoints';
import { LoyalityPointsLogReason } from '@prisma/client';

export const PointsAwardedNotification = observer(
  ({
    points,
    occasion,
  }: {
    points: number;
    occasion: LoyalityPointsLogReason;
  }) => {
    const { t } = useTranslation('notification');

    const reasonKey = `Points.Reason.${occasion}`;

    return (
      <Flex direction={'column'}>
        <ObtainablePoints points={points} />
        <Text
          c={'#fff'}
          style={{ fontWeight: 700, lineHeight: '24px' }}
          size={'16px'}
        >
          {t(reasonKey)}
        </Text>
        <Text
          c={'#fff'}
          style={{ fontWeight: 400, lineHeight: '16px' }}
          size={'14px'}
        >
          {t('Points.Congratulation', { points })}
        </Text>
      </Flex>
    );
  },
);

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import apiUrl from '../config/apiUrl';
import { z, ZodError } from 'zod';
import { RequestError } from './RequestError';
import { RequestErrorType } from './RequestErrorType';
import { ErrorCode } from '@web/common';
import { ServerError } from './ServerError';
import { keycloakStore } from '../keycloakStore';
import { Routes } from '../route/Routes';
import { showFailedRequestNotification } from '../component/notification';
import * as Sentry from '@sentry/react';

class AuthenticatedAPI {
  private readonly client: AxiosInstance;

  constructor(requestTimeout: number = 10000) {
    this.client = axios.create({
      timeout: requestTimeout,
      headers: { 'Content-Type': 'application/json' },
      baseURL: apiUrl,
    });
  }

  public async get<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
    queryParams?: object,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.get<ReturnType>(resourceUrl, {
          params: queryParams,
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  public async post<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
    config?: AxiosRequestConfig,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.post<ReturnType>(resourceUrl, data, {
          ...config,
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  public async patch<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.patch<ReturnType>(resourceUrl, data, {
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  public async put<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.put<ReturnType>(resourceUrl, data, {
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  public async noBodyPut<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.put<ReturnType>(resourceUrl, {
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  public async delete<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.delete<ReturnType>(resourceUrl, {
          headers: {
            ...this.extractAuthorizationHeader(),
          },
        }),
      schema,
    );
  }

  private async request<RequestData, ResponseData>(
    block: () => Promise<AxiosResponse<unknown, RequestData>>,
    schema: z.Schema<ResponseData>,
  ): Promise<ResponseData> {
    const response = await this.retryOnceFlow(block).catch((error) =>
      this.handleAPIError(error),
    );

    const parseResult = schema.safeParse(response.data);
    if (parseResult.success) {
      return parseResult.data;
    }

    throw this.handleParseError(parseResult.error, response.config);
  }

  private isStatusCode401(error: unknown) {
    if (error instanceof AxiosError) {
      return error.response?.status === 401;
    }
    return false;
  }

  private async retryOnceFlow<RequestData>(
    block: () => Promise<AxiosResponse<unknown, RequestData>>,
  ): Promise<AxiosResponse<unknown, RequestData>> {
    try {
      return await block();
    } catch (error: unknown) {
      if (this.isStatusCode401(error)) {
        // the first time we get a 401, we update the access token and retry the request
        await keycloakStore.updateToken();
        try {
          return await block();
        } catch (error: unknown) {
          if (this.isStatusCode401(error)) {
            // the second time we get a 401, we redirect the user to the login page
            keycloakStore.login();
            throw new RequestError(RequestErrorType.Unauthorized);
          } else {
            // if it is not a 401, we simply throw it
            throw error;
          }
        }
      } else {
        // if it is not a 401, we simply throw it
        throw error;
      }
    }
  }

  private handleAPIError = async (error: AxiosError): Promise<never> => {
    const apiResponseData = error?.response?.data as
      | undefined
      | { errorMessage?: string; error?: string; errorCode?: string };

    const statusCode = error.response?.status;
    const serverErrorCode = apiResponseData?.errorCode as undefined | ErrorCode;
    const serverTracingId = error.response?.headers['tracing-id'];

    console.error(
      JSON.stringify({
        statusCode: statusCode,
        error: error.message,
        tracingId: serverTracingId,
        apiResponse: apiResponseData,
      }),
    );

    // if the user was deactivated, we redirect him to the deactivated page
    // currently, this prompts a reload of keycloak
    // this means, if you log into a user, who's deactivated, (you'll see the loading page of keycloak twice, once the normal one, once the deactivated one (reload))
    if (serverErrorCode === ErrorCode.UserDeactivated) {
      window.location.href = `${Routes.Study}`;
    }

    if (serverErrorCode) {
      showFailedRequestNotification(serverErrorCode, serverTracingId);
      throw new ServerError(serverErrorCode);
    } else {
      showFailedRequestNotification(RequestErrorType.Generic, serverTracingId);
      throw new RequestError(RequestErrorType.Generic);
    }
  };

  private handleParseError<T>(error: ZodError<T>, config: AxiosRequestConfig) {
    Sentry.captureEvent({
      message: `Error: AuthenticatedAPI ${error.name} `,
      level: 'error',
      extra: { error },
    });

    throw new RequestError(
      RequestErrorType.ParseIssue,
      JSON.stringify({
        error: error,
        config,
      }),
    );
  }

  private extractAuthorizationHeader = () => {
    const token = keycloakStore.token;
    return {
      Authorization: `bearer ${token}`,
    };
  };
}

export const authenticatedAPI = new AuthenticatedAPI();

import { CohortType, UserStatus } from '@prisma/client';
import { AcceptedUserInfo } from './accepted-user/AcceptedUserInfo';

const deactivatedMsg = `Ein Mitarbeiter hat im Onboarding Gespräch festgestellt, dass
der Teilnehmer nicht geeignet ist für die Studie. Er wurde daher
vom Mitarbeiter abgelehnt`;
const deactivatedContraIndicationMsg = `Ein Mitarbeiter hat im Onboarding Gespräch festgestellt, dass
es eine Kontra Indikation gibt. Der Teilnehmer wird daher aus der
Studie ausgeschlossen.`;
const participantLeftVoluntarily = `Ein Mitarbeiter hat im Gespräch festgestellt, dass
der Teilnehmer freiwillig as der Studie austreten will.`;
const pausedContraIndicationMsg = `
Ein Mitarbeiter hat im Onboarding Gespräch festgestellt, dass
es eine Kontra Indikation gibt. In einem zweiten Schritt muss
der Teilnehmer seine Daten bei einem Arzt einreichen.`;
const pausedNoSmokingMsg = `Ein Mitarbeiter hat im Onboarding Gespräch festgestellt, dass
der Teilnehmer eventuell nicht kifft in seinem Alltag. In einem
zweiten Schritt, wird sein Status von einem Studienorganisator geprüft.`;
const voluntarilyLeftCause = 'Teilnehmer ist freiwillig ausgetreten';
const deactivatedCause = 'Ausschlussgrund:';

export function UserStatusInfo(
  status: UserStatus,
  pauseReason: string | null,
  contraIndication: string | null,
  contraIndicationDate: Date | null,
  participant: {
    cohortId: string;
    cohortType: CohortType;
    createdAt: Date;
    cannavigiaCode: string;
  } | null,
) {
  let description = '';
  let message = '';

  switch (status) {
    case UserStatus.DEACTIVATED:
      if (contraIndication) {
        if (contraIndication === voluntarilyLeftCause) {
          description = `Ausschlussgrund: ${contraIndication}`;
          message = participantLeftVoluntarily;
        } else if (contraIndication.includes(deactivatedCause)) {
          description = `Ausschlussgrund: ${contraIndication.replace(deactivatedCause, '')}`;
          message = deactivatedMsg;
        } else {
          description = `Kontraindikation: ${contraIndication}`;
          message = deactivatedContraIndicationMsg;
        }
      } else {
        description = 'Ablehnung im Gespräch';
        message = deactivatedMsg;
      }

      return {
        description,
        message,
      };
    case UserStatus.PAUSED:
      return contraIndicationDate
        ? {
            description: 'Kontraindikation: ' + pauseReason,
            message: pausedContraIndicationMsg,
          }
        : {
            description: pauseReason ?? '',
            message: pausedNoSmokingMsg,
          };
    case UserStatus.ACTIVE:
      return {
        description: '',
        message: AcceptedUserInfo(participant) ?? '',
      };
    default:
      return { description: '', message: '' };
  }
}

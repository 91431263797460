import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from '../../../route/Routes';
import { useEntryQuestionnaireStore } from '../../../store/EntryQuestionnaireStore';
import { observer } from 'mobx-react';
import { getEntryQuestionnaireSurveyJSON } from './entry-questionnaire-survey-specification';
// https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.i18n';
import { Button, Container } from '@mantine/core';
import classes from '../../../sharedStyles/Containers.module.css';
import { DevContent } from '../../../util/DevContent';
import { invalidSelection, validSelection } from './dev-selections';
import {
  entryQuestionnaireReqDto,
  EntryQuestionnaireReqDtoType,
  ErrorCode,
} from '@web/common';
import { Questionnaire } from '../../../component/questionnaire';
import { Header } from '../navigation';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useGoogleAnalyticsStore,
  useSnapPixelStore,
} from '../../../store';
import { showErrorNotification } from '../../../component/notification';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// TODO https://quickbird.atlassian.net/browse/SCC-52
export const EntryQuestionnaire = observer(() => {
  // TODO SCC-37 local storage of survey data https://surveyjs.io/form-library/documentation/how-to-save-and-restore-incomplete-survey
  const entryQuestionnaireStore = useEntryQuestionnaireStore();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const [initialData, setInitialData] = useState({});
  const { t } = useTranslation('entry-questionnaire');
  const surveyData = entryQuestionnaireStore.questionnaireResult;
  const surveyComplete = (surveyResult: EntryQuestionnaireReqDtoType) => {
    snapPixelStore.track(SnapEvent.Survey_Complete);
    googleAnalyticsStore.track(GoogleAnalyticsEvent.Survey_Complete);
    entryQuestionnaireStore.submitQuestionnaire(surveyResult);
  };

  if (surveyData) {
    if (entryQuestionnaireStore.isEligibleForParticipation) {
      const data = JSON.stringify(surveyData);
      sessionStorage.setItem('entryQuestionnaire', data);
      return <Navigate to={Routes.Register} />;
    } else {
      return <Navigate to={Routes.NotEligible} />;
    }
  }

  return (
    <>
      <Helmet>
        <title>Cannabis Studie Zürich: Anmeldung zur Studienteilnahme</title>
      </Helmet>
      <Header canLogout={false} />
      <Container fluid className={classes.container} maw="1200px">
        <Questionnaire
          title={t('Title')}
          questionnaire={{
            json: getEntryQuestionnaireSurveyJSON(t),
            schema: entryQuestionnaireReqDto,
            initial: entryQuestionnaireStore.questionnaireResult ?? initialData,
          }}
          onComplete={surveyComplete}
          onError={() =>
            showErrorNotification(ErrorCode.QuestionnaireParsingError)
          }
        />
      </Container>
      <DevContent>
        <Button onClick={() => setInitialData(validSelection())}>
          Valid Selection
        </Button>
        <Button onClick={() => setInitialData(invalidSelection())}>
          Invalid Selection
        </Button>
      </DevContent>
    </>
  );
});

import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Loader } from '@mantine/core';
import { usePodcastListStore } from '../../../../store/podcast';
import { PodcastListItem } from './PodcastListItem';
import classes from './podcast.module.css';
import { PodcastListVersion } from './Podcasts';
import { Routes } from '../../../../route/Routes';
import { useTranslation } from 'react-i18next';
import { InteractiveText } from '../../../../component/InteractiveText';

export const PodcastList = observer(
  ({ version }: { version: PodcastListVersion }) => {
    const { t } = useTranslation('podcast');
    const podcastListStore = usePodcastListStore();

    useEffect(() => {
      podcastListStore.getPodcasts();
    }, [podcastListStore]);

    const showAllComponent = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return (
            <InteractiveText
              text={t('List.Short.ShowAll')}
              navigateTo={`${Routes.Study}/${Routes.Podcasts}`}
            />
          );
        }
        case PodcastListVersion.Long: {
          return null;
        }
      }
    };

    if (podcastListStore.loading) {
      return <Loader />;
    }

    const items = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return podcastListStore.podcastList.slice(0, 3);
        }
        case PodcastListVersion.Long: {
          return podcastListStore.podcastList;
        }
      }
    };

    return (
      <div>
        <div className={classes.list}>
          {items().map((podcast) => (
            <PodcastListItem key={`podcast-${podcast.id}`} podcast={podcast} />
          ))}
        </div>
        <div>{showAllComponent()}</div>
      </div>
    );
  },
);
